import Skeleton from 'react-loading-skeleton';
import { ReactSortable, Sortable, Store } from 'react-sortablejs';

import { IDashboardData, IDashboardType, INewsComponent, INewsParams } from 'features/dashboard/interface/dashboard';
import { AddWidgetIcon } from 'shared/icons/icon';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import FavouriteSortableItem from './favouriteSortableItem';

interface Props {
	data: {
		showLabel: boolean;
		label: string;
		section: string;
		items: IDashboardData[];
		numberOfCards: boolean;
		showAddWidget: boolean;
	};
	news_widget_animation: boolean;
	deleteData: (id: string, isDelete: boolean) => void;
	onSaveNews: (newsId: number, action: boolean, index: number, section: string) => void;
	onSaveTender: (tenderId: number, action: boolean, index: number, section: string, rubrics: string) => void;
	toggleCardView: (elementId: string, view: string) => void;
	onEnd: (evt: Sortable.SortableEvent, sortable: Sortable | null, store: Store) => void;
	costModalUpdateView: (id: number) => void;
	loading: boolean;
}

const FavouriteSortableContainer = (props: Props) => {
	const { news_widget_animation, costModalUpdateView, data, deleteData, onEnd, onSaveNews, onSaveTender, toggleCardView, loading } = props;
	const { items, numberOfCards, section } = data;
	return (
		<ReactSortable
			className={`sortable__wrapper ${section}__wrapper`}
			list={items as any}
			setList={() => false}
			group='shared-group-name'
			multiDrag={false}
			forceFallback
			onEnd={onEnd}
			handle='.dashboard-icon__wrapper'
			sort={true}
			animation={350}
		>
			<>
				{loading && [1, 2, 3, 4].map(() => <Skeleton className='sortable__wrapper favorite__wrapper' />)}
				{items.map((data, index) => {
					return (
						<FavouriteSortableItem
							value={data}
							key={`${data.name}_${index}`}
							cardIndex={index}
							deleteData={deleteData}
							section={section}
							newsLength={(data.type === 'news' && (data as IDashboardType<INewsComponent[], INewsParams>).component_data.length) || 0}
							onSaveNews={onSaveNews}
							onSaveTender={onSaveTender}
							toggleCardView={toggleCardView}
							costModalUpdateView={costModalUpdateView}
							news_widget_animation={news_widget_animation}
						/>
					);
				})}
				{!loading && numberOfCards ? (
					<div
						className={`ignore-elements white-bg favourite-item__wrapper favourite-item__wrapper--${items.length} grid--${items.length} card medium-card d-flex justify-content-center align-items-center`}
					>
						<div className='d-flex justify-content-center align-items-center flex-column'>
							<AddWidgetIcon />
							<h1 className='text-dark-gray font--14px font-weight--500 line-height--20px no-margin'>
								<Translate text={localizationConstants.moveWidgetHere} />
							</h1>
						</div>
					</div>
				) : (
					<></>
				)}
			</>
		</ReactSortable>
	);
};

export default FavouriteSortableContainer;
