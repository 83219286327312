import React, { useEffect, useState } from 'react';

import { API_CONFIG } from 'shared/constants/constants';
import httpService from 'shared/services/http.service';

import { ICostModalChart } from '../interface/costModal';
import CostModalChartContainer from './costModalChart';

interface IProps {
	id: number;
	showHoverTooltip: boolean;
	cardIndex: number;
	indicators?: string[];
	timeSpan?: number;
	type?: string;
	selectedCurrancy?: string;
	customSpan?: { von: string; bis: string };
}

const CostModalChart: React.FC<IProps> = (props) => {
	const [chartData, setChartData] = useState<ICostModalChart[]>([]);
	const [loading, setLoading] = useState(false);
	const { id, indicators, type, timeSpan, customSpan, selectedCurrancy, showHoverTooltip, cardIndex } = props;

	useEffect(() => {
		getChartData();
	}, [selectedCurrancy]);

	const getChartData = async () => {
		setLoading(true);
		setChartData([]);
		try {
			const response = await httpService.get(`${API_CONFIG.path.getCostModalChart}/${id}?waehrung=${selectedCurrancy || ''}`);
			const editData = response.map((modal: any) => {
				return { date: new Date(modal.date), low: modal.erster, close: modal.erster, high: modal.hoch };
			});
			setChartData(editData);
			setLoading(false);
		} catch {
			setLoading(false);
		}
	};

	return (
		<CostModalChartContainer
			chartData={chartData}
			id={id}
			indicators={indicators}
			customSpan={customSpan}
			selectedCurrancy={selectedCurrancy}
			timeSpan={timeSpan}
			type={type}
			loading={loading}
			showHoverTooltip={showHoverTooltip}
			cardIndex={cardIndex}
		/>
	);
};

export default CostModalChart;
