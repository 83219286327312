import { isEmpty, round } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from 'shared/components/spinner/spinner';
import { PbBody, PbCell, PbHead, PbRow, PbTable, TableEmptyContainer, TableSpinner } from 'shared/components/table';
import { API_CONFIG, costModalTableHeader } from 'shared/constants/constants';
import { State } from 'shared/interface';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { ICostModal, ICostModalTable } from '../interface/costModal';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import { useTranslation } from 'react-i18next';

interface IProps {
	costModal: ICostModal;
	tableType: string;
}

const CostModalTable: React.FC<IProps> = (props) => {
	const { costModal, tableType } = props;
	const [costModalTableData, setCostModalTableData] = useState<ICostModalTable[]>([]);
	const [loading, setLoading] = useState(false);
	const { language } = useSelector((state: State) => state.siteConfig);
	const { t } = useTranslation();

	useEffect(() => {
		getCostModalTableData();
	}, []);

	const getCostModalTableData = () => {
		setLoading(true);
		HttpService.get(`${API_CONFIG.path.getCostModalTableData}/${costModal.id}`)
			.then((res) => {
				setCostModalTableData(res);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
	};

	return (
		<>
			{tableType === 'listTable' && (
				<div className={`card-items overflow-x-hidden`}>
					{loading && <Loader />}
					{!loading && isEmpty(costModalTableData) && <EmptyDataContainer />}
					{!loading &&
						!isEmpty(costModalTableData) &&
						costModalTableData.map((category, index) => (
							<React.Fragment key={`${category.symbol}-${category.boerse}-${index}`}>
								<div className='items border-bottom-grayscale'>
									<div className='item-title '>
										<h1 className='truncate--text no-margin font--14px line-height--20px font-weight--400 mb-8 cursor-pointer'>
											{language === 'en' && category.product_name_eng !== ''
												? category.product_name_eng
												: category.product_name || '-'}
										</h1>
									</div>
									<div className='item-values d-flex justify-content-space-between'>
										<div className='value width-33'>
											<span className='font--10px mb-2 font-weight--400 line-height--14px'>
												<Translate text={localizationConstants.date} />
											</span>
											<p className='font--14px font-weight--500 no-margin line-height--20px truncate--lines-1'>
												{category.datum || '-'}
											</p>
										</div>
										<div className='value width-33 text-align-center'>
											<span className='font--10px mb-2 font-weight--400 line-height--14px'>
												<Translate text={localizationConstants.price} />
											</span>
											<p className={`font--14px font-weight--500 no-margin line-height--20px`}>
												{round(category.hauptkurs, 2)}
											</p>
										</div>
										<div className='value width-33 text-align-right'>
											<span className='font--10px mb-2 font-weight--400 line-height--14px '>
												<Translate text={localizationConstants.change} />
											</span>
											<p
												className={`${
													category.hist_perf < 0 ? 'color--error-color' : 'color--success-color'
												} font--14px font-weight--500 no-margin line-height--20px `}
											>
												{`${!category.hist_perf ? '-' : round(category.hist_perf, 2)}%`}
											</p>
										</div>
									</div>
								</div>
							</React.Fragment>
						))}
					{isEmpty(costModalTableData) && (
						<h3 className='text-align-center mt-10'>
							<Translate text={localizationConstants.noCurrentData} />
						</h3>
					)}
				</div>
			)}
			{tableType === 'original' && (
				<PbTable>
					<PbHead>
						<PbRow>
							{costModalTableHeader(t).map((header) => {
								return (
									<PbCell header className={`${header.class} font--14px line-height--20px font-weight--400`} key={header.header}>
										{header.header}
									</PbCell>
								);
							})}
						</PbRow>
					</PbHead>
					<PbBody>
						{loading && <TableSpinner colSpan={4} />}
						{!loading && costModalTableData.length === 0 && <TableEmptyContainer colSpan={4} />}
						{!loading &&
							costModalTableData.length > 0 &&
							costModalTableData.map((category) => {
								return (
									<PbRow key={`${category.symbol}-${category.boerse}`}>
										<PbCell className='font--14px line-height--20px font-weight--600'>
											{(language === 'en' && category.product_name_eng !== '' && category.product_name_eng) ||
												category.product_name}
										</PbCell>
										<PbCell className='text-align-center font-12px line-height--17px font-weight--400'>{category.datum}</PbCell>
										<PbCell className='text-align-center font-14px line-height--20px font-weight--400'>
											{round(category.hauptkurs, 2)}
										</PbCell>
										<PbCell
											className={`${
												category.hist_perf < 0 ? 'color--error-color' : 'color--success-color'
											} text-align-center font-12px line-height--17px font-weight--400`}
										>
											{`${!category.hist_perf ? '0' : round(category.hist_perf, 2)}%`}
										</PbCell>
									</PbRow>
								);
							})}
					</PbBody>
				</PbTable>
			)}
		</>
	);
};

export default CostModalTable;
