import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import * as actionTypes from 'store/action-types';

import { API_CONFIG } from 'shared/constants/constants';
import httpService from 'shared/services/http.service';

import { useSelector } from 'react-redux';
import { State } from 'shared/interface';
import { ScaleType } from 'shared/interface/enums';
import { IAdvanceChartConfigType, IChartData, IChartDetails } from '../interface/futureTable';
import StockChart from './stockChart';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';
import { useDispatch } from 'react-redux';
import { createAction } from 'shared/util/utility';
import { t } from 'i18next';

interface IProps {
	chartDetails: IChartDetails;
}

const InventoryChart: React.FC<IProps> = (props) => {
	const { chartDetails } = props;
	const [loading, setLoading] = useState(false);
	const [chartData, setChartData] = useState<IChartData[]>([]);
	const [inventoryChartDetails, setInventoryChartDetails] = useState({} as IChartDetails);
	const [advanceChartConfig, setAdvanceChartConfig] = useState({
		chartSettings: [],
		percentageChange: false,
		logarithmicYAxis: false,
		scaleType: ScaleType.PRICE_AXIS,
		spanObj: { span: '1y' },
		chartType: 'area'
	} as IAdvanceChartConfigType);
	const { language, chartView } = useSelector((state: State) => state.siteConfig);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const getChartData = useCallback(async () => {
		const symbol = chartDetails.lagerbestand_symbol;
		const boerse = chartDetails.lagerbestand_boerse;
		setLoading(true);
		const payload = [
			{
				symbol,
				boerse
			}
		];
		try {
			const uniqueId = uuid();
			const response = await httpService.post(`${API_CONFIG.path.getChartData4}`, { data: payload });
			setInventoryChartDetails(response.headers[`${symbol}-${boerse}-0`]);
			setChartData(response.data);
			const chartSet = [
				{
					id: uniqueId,
					name:
						(language === 'en' &&
							response.headers[`${symbol}-${boerse}-0`]?.name_eng !== '' &&
							response.headers[`${symbol}-${boerse}-0`]?.name_eng) ||
						response.headers[`${symbol}-${boerse}-0`]?.name,
					symbol: response.headers[`${symbol}-${boerse}-0`]?.symbol,
					boerse: response.headers[`${symbol}-${boerse}-0`]?.boerse,
					unit: response.headers[`${symbol}-${boerse}-0`]?.einheit,
					currency: response.headers[`${symbol}-${boerse}-0`]?.waehrung,
					indicators: [],
					history: { label: t(localizationConstants.none), value: '' },
					axis: {
						label: 'Right',
						value: 'right'
					},
					color: '#4472C4',
					disallowed: response.headers[`${symbol}-${boerse}-0`]?.disallowed,
					basis_symbol: response.headers[`${symbol}-${boerse}-0`]?.basis_symbol
				}
			];
			let copyOfAdvanceChartConfig = { ...advanceChartConfig };
			copyOfAdvanceChartConfig = {
				...copyOfAdvanceChartConfig,
				chartSettings: chartSet
			};
			setAdvanceChartConfig(copyOfAdvanceChartConfig);
			setLoading(false);
		} catch {
			setLoading(false);
		}
	}, [chartDetails.lagerbestand_boerse, chartDetails.lagerbestand_symbol]);

	const handleNavigation = () => {
		dispatch(createAction(actionTypes.UPDATE_CHART_CONFIG, true));
		navigate(
			`/materials/futureTable?boerse=${chartDetails.lagerbestand_boerse}&basis_symbol=${chartDetails.basis_symbol}&symbol=${chartDetails.lagerbestand_symbol}&currency=${chartDetails.waehrung}`,
			{
				state: {
					chartSelection: {
						span: '1y',
						type: chartView === ScaleType.PERCENTAGE_CHANGE ? 'line' : 'area',
						currency: '',
						customChartSpan: undefined
					}
				}
			}
		);
		const scrollableDiv = document.getElementById('scrollable-wrapper');
		if (scrollableDiv) {
			scrollableDiv.scrollTop = 0;
		}
	};

	useEffect(() => {
		getChartData();
	}, [getChartData]);

	const onMouseEnter = () => {
		const pageWrapper = document.getElementById('scrollable-wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'hidden';
		}
	};
	const onMouseLeave = () => {
		const pageWrapper = document.getElementById('scrollable-wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'auto';
		}
	};

	return (
		(!isEmpty(chartData) && !isEmpty(chartDetails) && !isEmpty(advanceChartConfig.chartSettings) && (
			<div
				className='forward-curve-container shadow-box width-half bg-color--white br--6px'
				onMouseEnter={onMouseEnter}
				onMouseLeave={onMouseLeave}
			>
				<div
					onClick={handleNavigation}
					className='bg-color--primary cursor-pointer text-white forward-curve-header font--16px font-weight--700 line-heigh--22pxt pl-24 pt-8 pb-8 br--6px'
				>
					<Translate text={localizationConstants.inventoryChart} />
				</div>
				<div className='inventory-chart' id={`chart-container-inventory-${chartDetails.lagerbestand_symbol}-0`}>
					<StockChart
						chartData={chartData}
						chartType={'area'}
						showZoomButton={false}
						loading={loading}
						chartDetails={inventoryChartDetails}
						cardIndex={0}
						chartDesignConfig={{
							margin: {
								left: 20,
								right: 50,
								top: 20,
								bottom: 10
							},
							fontSize: 10,
							rectHeight: 0,
							rectWidth: 0
						}}
						spanObj={{ span: '1y' }}
						chartName='inventory'
						chartSettings={advanceChartConfig.chartSettings}
						scaleType={'price'}
						allChartDetails={{}}
					/>
				</div>
			</div>
		)) || <></>
	);
};

export default InventoryChart;
