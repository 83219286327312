import { isEmpty } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from 'shared/components/form/button';
import { CrossIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import { createAction } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';

import { IAdvanceChartConfigType, IChartDetails, IChartSettingsType } from 'features/futuretable/interface/futureTable';
import ChartHoverTooltip from './chartHoverTooltip';
import DragHandle from './dragHandle';

interface Props {
	name: string;
	elementId: string;
	action: boolean;
	deleteData: (data: string, action: boolean) => void;
	navigateUrl?: string;
	section: string;
	advanceChartConfig?: IAdvanceChartConfigType;
	cardIndex?: number;
	updatedChartSettings?: IChartSettingsType[];
	navigateToPage?: () => void;
	chartDetails?: { [key: string]: IChartDetails };
}

const DashboardCardHeader: React.FC<Props> = (props) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const [isShowPopup, setIsShowPopup] = useState<{ [key: string]: boolean }>({});
	const { action, deleteData, elementId, name, navigateUrl, section, advanceChartConfig, cardIndex, updatedChartSettings, navigateToPage } = props;
	const { chartLastObj } = useSelector((state: State) => state.chartConfigReducer);
	const chart = advanceChartConfig?.chartSettings[0];
	const isIframe = location.pathname.includes('iframe');

	return (
		<>
			<div className='card-header__wrapper border-bottom-grayscale d-flex justify-content-space-between full-width align-items-center'>
				<div className='header-info__wrapper d-flex align-items-center'>
					<DragHandle section={section} />
					<p
						onClick={() => {
							dispatch(createAction(actionTypes.UPDATE_CHART_CONFIG, false));
							dispatch(createAction(actionTypes.UPDATE_CHART_CONFIG_FROM_DASHBOARD, true));
							navigateToPage && navigateToPage();
							navigateUrl && navigate(navigateUrl);
						}}
						className='card-header font--16px ml-10 no-margin cursor-pointer truncate--lines-1 font-weight--600 line-height--22px'
						onMouseOver={() => {
							setIsShowPopup({ ...isShowPopup, [elementId]: true });
						}}
						onMouseOut={() => setIsShowPopup({ ...isShowPopup, [elementId]: false })}
					>
						{chart?.name || name}
					</p>
				</div>
				{!isIframe && (
					<div className='cross-icon__wrapper d-flex align-items-center justify-content-center'>
						<Button className='no-border height--auto' onClick={() => deleteData(elementId, action)}>
							<CrossIcon className='cross-icon' />
						</Button>
					</div>
				)}
			</div>
			{!isEmpty(updatedChartSettings) &&
				updatedChartSettings &&
				chartLastObj[`${chart?.symbol}-${chart?.boerse}`] &&
				isShowPopup[elementId] && (
					<ChartHoverTooltip
						elementId={elementId}
						cardIndex={cardIndex as number}
						chartLastObj={chartLastObj[`${chart?.symbol}-${chart?.boerse}`]}
						updatedChartSettings={updatedChartSettings as IChartSettingsType[]}
						chartDetails={props.chartDetails}
					/>
				)}
		</>
	);
};

export default DashboardCardHeader;
