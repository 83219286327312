import isEmpty from 'lodash/isEmpty';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import Button from 'shared/components/form/button';
import { notify } from 'shared/components/notification/notification';
import { Loader } from 'shared/components/spinner/spinner';
import { PbBody, PbCell, PbHead, PbRow, PbTable } from 'shared/components/table';
import { API_CONFIG } from 'shared/constants/constants';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { EditIcon, LockIcon, MinimizeSideMenuIcon } from 'shared/icons/icon';
import { State } from 'shared/interface';
import httpService from 'shared/services/http.service';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { customNumberFormatter, exportFutureTableData } from 'shared/util/utility';
import PermissionManager from 'shared/hoc/permission/permissionManager';
import { adminPermissionMapping } from 'shared/constants/permissionConstant';

import { IChartData, IChartDetails, IChartItem, IFutureTable, IFutureTableItems } from '../interface/futureTable';
import MaterialTable from './materialTable';

interface IProps {
	loading: boolean;
	materialTableLoading: boolean;
	futureTableData: IFutureTable;
	chartData: IChartData[];
	chartDetails: IChartDetails;
	currency: string;
	handleOnRowClick: (data: IFutureTableItems) => void;
	setIsTableOpen: (action: boolean) => void;
	isTableOpen: boolean;
	setIsChartSettingUpdate: () => void;
	dateMatchKey: string;
}

const MaterialTables: React.FC<IProps> = (props) => {
	const { language, admin } = useSelector((state: State) => state.siteConfig);
	const { t } = i18n;
	const { boerse, currency } = useQueryParams();
	const [action, setAction] = useState(admin.length ? 'material' : 'future');
	const [saveMode, setIsSaveMode] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [excelLoading, setExcelLoading] = useState(false);
	const {
		loading,
		futureTableData,
		chartDetails,
		materialTableLoading,
		handleOnRowClick,
		isTableOpen,
		setIsTableOpen,
		setIsChartSettingUpdate,
		dateMatchKey
	} = props;

	const chartData = useMemo(() => [...props.chartData].reverse(), [props.chartData]);

	const tableHeadings = [
		`${t(localizationConstants.dueDate)}`,
		`${t(localizationConstants.courseTime)}`,
		`${t(localizationConstants.price)}`,
		`${t(localizationConstants.change)}`
	];

	useEffect(() => {
		setIsSaveMode(false);
	}, [isTableOpen]);

	const addFutureTableToDashboard = () => {
		setActionLoading(true);
		const { basis_symbol, symbol, waehrung } = futureTableData.details;
		const payload = {
			boerse: boerse,
			basis_symbol: basis_symbol,
			waehrung: waehrung,
			symbol: symbol
		};
		httpService
			.post(API_CONFIG.path.addFutureTableToDashboard, payload)
			.then(() => {
				setActionLoading(false);
				notify(
					t(chartDetails.isChartAlreadyAdded ? localizationConstants.updatedToDashboard : localizationConstants.addedToDashboard),
					'success',
					{
						id: 'futureTableAdd'
					}
				);
			})
			.catch((err) => {
				setActionLoading(false);
				console.error(err);
			});
	};

	const exportFutureTableXLS = () => {
		setExcelLoading(true);
		const { basis_symbol, symbol } = futureTableData.details;
		const payload = {
			waehrung: currency,
			basis_symbol: basis_symbol,
			boerse: boerse,
			symbol: symbol
		};
		httpService
			.get(API_CONFIG.path.exportFutureTableXLS, payload)
			.then((response) => {
				const itemsArray = [] as any;
				const isEnglish = language === 'en';
				const tableTitle = [isEnglish ? 'Maturity' : 'falligkeit', isEnglish ? 'Date' : 'datum', isEnglish ? 'Price' : 'kurs'];
				response.items.map((data: any) => {
					const values = Object.values(data);
					itemsArray.push(values);
				});
				const detailsArray = [
					['name', response.details.name],
					[isEnglish ? 'Currency' : 'waehrung', response.details.waehrung],
					[isEnglish ? 'Org. currency' : 'wahrung_original', response.details.wahrung_original],
					[isEnglish ? 'Unit' : 'einheit', response.details.einheit]
				];
				exportFutureTableData(detailsArray, [tableTitle, ...itemsArray]);
				setExcelLoading(false);
			})
			.catch((err) => {
				setExcelLoading(false);
				console.error(err);
			});
	};

	return (
		<Fragment>
			{!isTableOpen && chartDetails && (
				<div className='d-flex width-19 mb-8 border--1px-solid-accent-yellow b-radius--6px'>
					{!isEmpty(admin) && (
						<Button
							type='button'
							id='material-button'
							btnType={'secondary'}
							className={`button-size--medium ${!isEmpty(futureTableData.items) && 'btr-radius--0 bbr-radius--0'}`}
							onClick={() => {
								setAction('material');
								setIsTableOpen(true);
							}}
						>
							<Translate text={localizationConstants.materials} />
						</Button>
					)}
					{!isEmpty(futureTableData.items) && (
						<Button
							type='button'
							btnType={'secondary'}
							id='future-button'
							className={`button-size--medium ${(!isEmpty(admin) && 'btl-radius--0 bbl-radius--0') || ''}`}
							onClick={() => {
								setAction('future');
								setIsTableOpen(true);
							}}
						>
							Futures
						</Button>
					)}
				</div>
			)}
			<div className={`futuretable bg-color--white ${isTableOpen ? 'width-30' : 'width--0'}`}>
				<div className='future-table-container'>
					{isTableOpen && chartDetails && (
						<div className='toggle-btn-wrapper d-flex d-flex justify-content-space-between align-items-center'>
							<>
								<div className='d-flex width-33'>
									{!isEmpty(admin) && (
										<Button
											type='button'
											btnType={action === 'material' ? 'primary' : 'secondary'}
											className={`button-size--medium ${!isEmpty(futureTableData.items) && 'btr-radius--0 bbr-radius--0'}`}
											onClick={() => setAction('material')}
										>
											<Translate text={localizationConstants.materials} />
										</Button>
									)}
									{!isEmpty(futureTableData.items) && (
										<Button
											type='button'
											btnType={action === 'future' ? 'primary' : 'secondary'}
											className={`button-size--medium ${(!isEmpty(admin) && 'btl-radius--0 bbl-radius--0') || ''}`}
											onClick={() => setAction('future')}
										>
											Futures
										</Button>
									)}
								</div>
								{action === 'material' && chartDetails.notierung && <DataTypeButtons chartDetails={chartDetails} />}
								<div className='width-33 d-flex justify-content-end align-items-center'>
									{action === 'material' && chartDetails?.waehrung_org === chartDetails?.waehrung && (
										<PermissionManager permission={adminPermissionMapping.superDataAdmin}>
											<Button
												icon={(!saveMode && <EditIcon className='mr-8' />) || <></>}
												iconPosition='left'
												className='bg-color--admin-color button-size--small'
												onClick={() => setIsSaveMode(!saveMode)}
											>
												<Translate text={!saveMode ? localizationConstants.editPrice : localizationConstants.save} />
											</Button>
										</PermissionManager>
									)}
								</div>
							</>

							<button
								className={`btn table-collapse-btn d-flex justify-content-center align-items-center`}
								onClick={() => setIsTableOpen(false)}
								id={'collapse-button'}
							>
								<MinimizeSideMenuIcon />
							</button>
						</div>
					)}
					{loading && isTableOpen && (
						<div className='d-flex justify-content-center align-items-center '>
							<Loader />
						</div>
					)}
					{isTableOpen && (
						<div className='material-table__wrapper'>
							{!loading && action === 'future' && (
								<PbTable>
									<PbHead>
										<PbRow>
											{tableHeadings.map((title, index) => (
												<PbCell className='text-align-center' key={index} header>
													{title}
												</PbCell>
											))}
										</PbRow>
									</PbHead>
									<PbBody>
										{!loading &&
											!isEmpty(futureTableData.items) &&
											futureTableData.items.map((data, index) => (
												<PbRow key={index}>
													<PbCell onClick={() => handleOnRowClick(data)} className='font--14px font-weight--600'>
														<span className='cursor-pointer'>{data.faelligkeit_f}</span>
													</PbCell>
													<PbCell className='text-align-center font--12px font-weight--500'>
														{data.kurszeit_f || <LockIcon />}
													</PbCell>
													<PbCell className='text-align-center font--14px font-weight--500'>
														{data.disallowed === 0 ? (
															data.hauptkurs ? (
																customNumberFormatter(data.hauptkurs)
															) : (
																'-'
															)
														) : (
															<LockIcon />
														)}
													</PbCell>
													<PbCell
														className={`text-align-center font--12px font-weight--500 
																		${data.diff_prozent < 0 && 'text-red'} 
																		${data.diff_prozent > 0 && 'text-green'} 
																		`}
													>
														{data.diff_prozent > 0 && futureTableData.details.disallowed === 0 && '+'}
														{data.disallowed === 0 ? (
															data.diff_prozent ? (
																customNumberFormatter(data.diff_prozent) + '%'
															) : (
																'-'
															)
														) : (
															<LockIcon />
														)}
													</PbCell>
												</PbRow>
											))}
									</PbBody>
								</PbTable>
							)}
							{action === 'material' && !isEmpty(admin) && !isEmpty(chartData) && !isEmpty(chartDetails) && (
								<MaterialTable
									loading={materialTableLoading}
									chartData={chartData.filter((data: IChartData) => {
										const key = data[dateMatchKey] as IChartItem;
										return key?.d === data.date && new Date(data.date) <= new Date();
									})}
									saveMode={saveMode}
									chartDetails={chartDetails}
									setIsChartSettingUpdate={setIsChartSettingUpdate}
								/>
							)}
							{!loading && action === 'future' && isEmpty(futureTableData.items) && <EmptyDataContainer />}
							{action === 'future' && !isEmpty(futureTableData.items) && (
								<div className='d-flex justify-content-end align-items-center export-btn-container '>
									<Button
										btnType='primary'
										onClick={addFutureTableToDashboard}
										className='mr-10 padding-left-right--16px font--14px line-height--20px font-weight--500'
										loading={actionLoading}
										disabled={actionLoading}
									>
										<Translate text={localizationConstants.addToDashboard} />
									</Button>
									<Button
										btnType='secondary'
										className='padding-left-right--16px font--14px line-height--20px font-weight--500'
										onClick={exportFutureTableXLS}
										disabled={futureTableData.details.disallowed === 1 ? true : false}
										loading={excelLoading}
									>
										<Translate text={localizationConstants.exportPriceAsExcel} />
									</Button>
								</div>
							)}
						</div>
					)}
				</div>
			</div>
		</Fragment>
	);
};

interface IDataTypeButtons {
	chartDetails: IChartDetails;
}

const DataTypeButtons: React.FC<IDataTypeButtons> = (props) => {
	const { chartDetails } = props;
	const { t } = i18n;
	const chartUpdate: { [key: string]: string } = {
		D: `${t(localizationConstants.chartUpdateDaily)}`,
		M: `${t(localizationConstants.chartUpdateMonthly)}`,
		W: `${t(localizationConstants.chartUpdateWeekly)}`,
		Q: `${t(localizationConstants.chartUpdateQuarterly)}`,
		Y: `${t(localizationConstants.chartUpdateYearly)}`
	};
	return (
		<div className='width-33 d-flex justify-content-center align-items-center'>
			<Button btnType={'primary'} className={`button-size--small height--28px cursor-unset`}>
				{chartUpdate[chartDetails.notierung]}
			</Button>
		</div>
	);
};

export default MaterialTables;
