import { every, filter, isEmpty, maxBy, uniqBy } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { FormikValues } from 'formik';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';

import { notify } from 'shared/components/notification/notification';
import { API_CONFIG, STROKESCOLORS, colorPallet } from 'shared/constants/constants';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { State } from 'shared/interface';
import { replaceHashtagFromUrl } from 'shared/util/utility';
import HttpService from 'shared/services/http.service';
import { createAction, defineChartMargins } from 'shared/util/utility';
import * as actionTypes from 'store/action-types';
import { LoginResponse } from 'features/login/interface/login';
import EmptyDataContainer from 'shared/components/emptyDataContainer/emptyDataContainer';
import { Loader } from 'shared/components/spinner/spinner';
import { ChartAxisPosition, ScaleType } from 'shared/interface/enums';
import AuthService from 'shared/services/auth.service';
import httpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import PermissionManager from 'shared/hoc/permission/permissionManager';
import { adminPermissionMapping } from 'shared/constants/permissionConstant';

import MaterialTables from '../component/materialTables';
import AdminExportButtons from '../component/adminExportButtons';
import ChartAdditionalInfo from '../component/chartAdditionalInfo';
import ChartConfigurationDropdowns from '../component/chartConfigurationDropdowns';
import ChartHeader from '../component/chartHeader';
import ChartSettingsPopup from '../component/chartSettingsPopup';
import ExportMaterialDataButtons from '../component/exportmaterialDataButtons';
import StockChart from '../component/stockChart';
import { useAdvanceChartConfig } from '../hooks/useAdvanceChartConfig';
import {
	IAdvanceChartConfigType,
	IChartData,
	IChartDetails,
	IChartPayloadType,
	IChartSettingsType,
	ICompareChartIndicators,
	ISearchMaterialData
} from '../interface/futureTable';
import { Translate } from 'shared/util/translation/translate';

export type IComparativeChartArrayType = ISearchMaterialData[] | ICompareChartIndicators[];

const ChartScreenContainer: React.FC = () => {
	const { t } = useTranslation();
	const { timeSpan, currency } = useQueryParams();
	const { language, chartView } = useSelector((state: State) => {
		return { ...state.siteConfig, ...state.chartConfigReducer, ...state.dashboardChartReducer };
	});
	const [loading, setLoading] = useState(false);
	const [chartData, setChartData] = useState([] as IChartData[]);
	const [futureTableLoading, setFutureTableLoading] = useState(false);
	const [futureTableData, setFutureTableData] = useState([] as any);
	const [strokeColors] = useState(STROKESCOLORS);
	const [chartColor, setChartColor] = useState('');
	const [action, setAction] = useState('');
	const location = useLocation();
	const navigate = useNavigate();
	const [updateChartViewLoading, setUpdateChartViewLoading] = useState(false);
	const [isTableOpen, setIsTableOpen] = useState(false);
	const [allChartDetails, setAllChartDetails] = useState({} as { [key: string]: IChartDetails });
	const [updatedAllChartDetails, setUpdatedAllChartDetails] = useState({} as { [key: string]: IChartDetails });
	const [priceListLoading, setPriceListLoading] = useState(false);
	const [isIntraday, setIsIntraday] = useState(false);
	const [isVisibleForecastNotation, setIsVisibleForecastNotation] = useState<boolean>(false);
	const [isVisiblePdfModuleNotation, setIsVisiblePdfModuleNotation] = useState<boolean>(false);
	const [rearrangedData, setRearrangedData] = useState<any>([]);
	const [updatedChartSettings, setUpdatedChartSettings] = useState<IChartSettingsType[]>([]);
	const [barWidth, setBarWidth] = useState<{ [key: string]: number }>({});
	const dispatch = useDispatch();

	const {
		chartSettings,
		setChartSettings,
		scaleType,
		setScaleType,
		chartType,
		setChartType,
		spanObj,
		setSpanObj,
		basis_symbol,
		boerse,
		symbol,
		currency: defaultCurrency,
		setIsChartSettingUpdate,
		isChartSettingUpdate
	} = useAdvanceChartConfig();

	useEffect(() => {
		if (!location.pathname.includes('chartScreen')) {
			setIsTableOpen(false);
			let payload: any = {
				boerse: boerse,
				symbol: symbol
			};
			if (basis_symbol !== '') {
				payload = {
					...payload,
					basis_symbol: basis_symbol,
					waehrung: (defaultCurrency !== 'null' && defaultCurrency) || ''
				};
			}
			fetchFutureTableData(payload);
		}
	}, [basis_symbol, symbol, boerse, defaultCurrency, location.pathname]);

	const fetchFutureTableData = (payload: any) => {
		setFutureTableLoading(true);
		HttpService.get(API_CONFIG.path.futuretable, payload)
			.then((response) => {
				setFutureTableLoading(false);
				setFutureTableData(response);
			})
			.catch((err) => {
				setFutureTableLoading(false);
				console.error(err);
			});
	};

	const chartOrder = useCallback(
		(data: any) => {
			const convertedArray = chartSettings.map((item, settingIndex) => {
				const maxPrice = maxBy(data, (d: any) => d[`cv${item.symbol}-${item.boerse}-${settingIndex}`]?.l);
				return {
					oldKey: `cv${item.symbol}-${item.boerse}-${settingIndex}`,
					value: maxPrice,
					index: settingIndex,
					symbol: item.symbol,
					boerse: item.boerse
				};
			});
			return convertedArray;
		},
		[chartSettings]
	);

	const fetchChartData = useCallback(
		async (payload: IChartPayloadType) => {
			setChartData([]);
			setAllChartDetails({});
			setLoading(true);
			const response = await httpService.post(API_CONFIG.path.getChartData4, { ...payload });
			const chartHighValues = chartOrder(response.data);
			const sortedData = [...chartHighValues].sort((a, b) => a.value - b.value);
			const newData = sortedData.map((entry, index) => ({
				...entry,
				newIndex: index,
				updatedKey: `cv${entry.symbol}-${entry.boerse}-${index}`
			}));
			response.data.forEach((objToUpdate: any) => {
				newData.forEach((update) => {
					const { oldKey, updatedKey } = update;
					if (Object.prototype.hasOwnProperty.call(objToUpdate, oldKey)) {
						objToUpdate[updatedKey] = objToUpdate[oldKey];
						if (oldKey !== updatedKey) {
							delete objToUpdate[oldKey];
						}
					}
				});
			});
			const copyOfResponseHeaders = { ...response.headers };
			const tempPayload: any = [];
			const tempObj: any = {};
			for (let i = 0; i < newData.length; i++) {
				const { index, symbol, boerse, newIndex } = newData[i];
				tempPayload.push(payload.data[index]);
				tempObj[`${symbol}-${boerse}-${newIndex}`] = response.headers[`${symbol}-${boerse}-${index}`];
			}
			tempPayload.map((data: any, index: number) => {
				tempObj[`${data.symbol}-${data.boerse}-${index}`].chartPrice =
					response.data.length > 0 ? response.data[response.data.length - 1][`cv${data.symbol}-${data.boerse}-${index}`]?.l : 0;
			});
			setRearrangedData(newData);
			setChartData(response.data);
			setUpdatedAllChartDetails(tempObj);
			setAllChartDetails(copyOfResponseHeaders);
			setIsIntraday(response.isIntraday);
			setLoading(false);
		},
		[chartOrder]
	);

	const fetchSingleChartData = useCallback(
		(payload: IChartPayloadType) => {
			setChartData([]);
			setAllChartDetails({});
			setLoading(true);
			httpService
				.post(API_CONFIG.path.getChartData4, { ...payload })
				.then((response: any) => {
					const copyOfResponseHeaders = { ...response.headers } as { [key: string]: IChartDetails };
					setAllChartDetails(copyOfResponseHeaders);
					setUpdatedAllChartDetails(copyOfResponseHeaders);
					setChartData(response.data);
					// setReversedChartData([...response.data].reverse());
					setIsIntraday(response.isIntraday);
					setLoading(false);
					setIsChartSettingUpdate(false);
					setChartSettings((cs) => {
						const unit = {
							label: response.headers[`${cs[0].symbol}-${cs[0].boerse}-0`]?.einheit || '',
							value: response.headers[`${cs[0].symbol}-${cs[0].boerse}-0`]?.einheit || ''
						};
						const name =
							(language === 'en' &&
								response.headers[`${cs[0].symbol}-${cs[0].boerse}-0`]?.name_eng !== '' &&
								response.headers[`${cs[0].symbol}-${cs[0].boerse}-0`]?.name_eng) ||
							response.headers[`${cs[0].symbol}-${cs[0].boerse}-0`]?.name ||
							'';
						const copyOfChartSettings = [...cs];
						copyOfChartSettings[0] = {
							...copyOfChartSettings[0],
							name: name,
							disallowed: response.headers[`${cs[0].symbol}-${cs[0].boerse}-0`]?.disallowed || 0,
							unit: unit
						};
						return uniqBy(copyOfChartSettings, (item) => [item.symbol, item.boerse, item.id].join());
					});
				})
				.catch((error) => {
					setLoading(false);
					console.error(error, 'error');
				});
		},
		[language, setChartSettings, setIsChartSettingUpdate]
	);

	useEffect(() => {
		if (spanObj && chartType && !isEmpty(chartSettings)) {
			const { span, endDate, startDate } = spanObj;
			let payload: IChartPayloadType = {
				data: chartSettings.map((chart: IChartSettingsType) => {
					return {
						symbol: chart.symbol,
						boerse: chart.boerse,
						waehrung: chart.currency.value,
						unit: chart.unit?.value || '',
						year: parseInt(chart.history.value) || ''
					};
				})
			};
			if (span) {
				payload = {
					...payload,
					span: chartType === 'bar' ? 'max' : span
				};
			} else if (startDate && endDate) {
				payload = {
					...payload,
					start_date: startDate,
					end_date: endDate
				};
			}
			if (!location.pathname.includes('chartScreen') && chartSettings.length === 1) {
				if (isChartSettingUpdate) {
					setBarWidth({});
					fetchSingleChartData(payload);
				}
			} else if (location.pathname.includes('chartScreen') && chartSettings.length >= 2) {
				setBarWidth({});
				fetchChartData(payload);
			}
		}
	}, [chartSettings, chartType, spanObj, fetchSingleChartData, location.pathname, fetchChartData, isChartSettingUpdate]);

	const handleChartType = (selectedOption: string) => {
		if (chartSettings.length > 3 && selectedOption === 'bar') {
			notify(t(localizationConstants.barChartNumberValidationMessage), 'error');
			return;
		} else if (scaleType === ScaleType.PERCENTAGE_CHANGE) {
			notify(t(localizationConstants.barChartErrorMessage), 'error');
			return;
		}
		if (selectedOption === 'bar') {
			setChartData([]);
			setSpanObj({ span: '1y' });
		} else if (selectedOption === 'candle') {
			setSpanObj({ span: '6m' });
		}
		setChartType(selectedOption);
		setIsChartSettingUpdate(true);
	};

	const getComparativeChartData = useCallback(
		(comparativeData: ISearchMaterialData) => {
			if (chartType === 'bar' && chartSettings.length > 2) {
				notify(t(localizationConstants.barChartNumberValidationMessage), 'error');
			} else if (chartSettings.length < 9) {
				const copyOfChartSettings = [
					...chartSettings.map((item: any) => {
						return {
							...item,
							axis: {
								label: chartView === ScaleType.PERCENTAGE_CHANGE ? 'Left' : 'Right',
								value: chartView === ScaleType.PERCENTAGE_CHANGE ? 'left' : 'right'
							}
						};
					})
				];
				const isCompareWithSelfEnabled = copyOfChartSettings.some((chart) => chart.history.value !== '');
				copyOfChartSettings.push({
					id: uuid(),
					name:
						language === 'en' && comparativeData.name_eng !== ''
							? comparativeData.name_eng
							: comparativeData.name_deu !== ''
							? comparativeData.name_deu
							: comparativeData.name,
					symbol: comparativeData.symbol,
					boerse: comparativeData.boerse,
					disallowed: comparativeData.disallowed,
					unit: { label: comparativeData.einheit, value: comparativeData.einheit },
					currency: { label: comparativeData.waehrung, value: comparativeData.waehrung },
					indicators: [],
					history: {
						label: `${isCompareWithSelfEnabled ? new Date().getFullYear() - 1 : t(localizationConstants.none)}`,
						value: `${isCompareWithSelfEnabled ? new Date().getFullYear() - 1 : ''}`
					},
					axis: {
						label: chartView === ScaleType.PERCENTAGE_CHANGE ? 'Left' : 'Right',
						value: chartView === ScaleType.PERCENTAGE_CHANGE ? 'left' : 'right'
					},
					color: colorPallet[copyOfChartSettings.length],
					basis_symbol: comparativeData.basis_symbol
				});
				const { span, endDate, startDate } = spanObj;
				const locationSpan = chartType === 'bar' ? '1y' : span;
				const state = {
					advanceChartConfig: {
						chartSettings: isCompareWithSelfEnabled
							? copyOfChartSettings
							: uniqBy(copyOfChartSettings, (item) => [item.symbol, item.boerse, item.id].join())
					} as IAdvanceChartConfigType,
					chartSelection: {
						type: chartView === ScaleType.PERCENTAGE_CHANGE ? 'line' : chartType,
						span: locationSpan,
						customChartSpan: {
							von: startDate,
							bis: endDate
						}
					}
				};

				navigate(`/materials/chartScreen`, {
					state: state
				});
			} else if (chartSettings.length > 8) {
				notify('please change chart type to add more prices', 'error');
			}
		},
		[chartSettings, chartType, chartView, language, navigate, spanObj]
	);

	const handleSpan = (value: any) => {
		setBarWidth({});
		setSpanObj({ span: value });
		setIsChartSettingUpdate(true);
	};

	const handleOnRowClick = (data: any) => {
		setChartSettings([
			{
				symbol: data.symbol,
				boerse: data.boerse,
				basis_symbol: basis_symbol,
				currency: {
					label: defaultCurrency || '',
					value: defaultCurrency || ''
				},
				axis: {
					label: chartView === ScaleType.PERCENTAGE_CHANGE ? 'Left' : 'Right',
					value: chartView === ScaleType.PERCENTAGE_CHANGE ? 'left' : 'right'
				},
				color: colorPallet[0],
				disallowed: 0,
				history: {
					label: t(localizationConstants.none),
					value: ''
				},
				id: uuid(),
				indicators: [],
				name: '',
				unit: {
					label: '',
					value: ''
				}
			}
		]);
		setIsChartSettingUpdate(true);
	};

	const onMouseEnter = () => {
		const pageWrapper = document.getElementById('scrollable-wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'hidden';
		}
	};
	const onMouseLeave = () => {
		const pageWrapper = document.getElementById('scrollable-wrapper');
		if (pageWrapper) {
			pageWrapper.style.overflow = 'auto';
		}
	};

	useEffect(() => {
		const tempArray = [];
		if (!isEmpty(rearrangedData)) {
			for (let i = 0; i < rearrangedData.length; i++) {
				const { index } = rearrangedData[i];
				if (chartSettings[index]) {
					tempArray.push(chartSettings[index]);
				}
			}
		}
		setUpdatedChartSettings(!location.pathname.includes('chartScreen') ? chartSettings : tempArray);
	}, [chartSettings, rearrangedData]);

	const handleAdvanceChartConfiguration = (values: FormikValues) => {
		const data = { ...values };
		setUpdateChartViewLoading(true);
		HttpService.put(API_CONFIG.path.updateChartView, { chart_view: data.scaleType })
			.then(() => {
				setAction('');
				const authData = AuthService.getAuthData() as LoginResponse;
				AuthService.setAuthData({ ...authData, chart_view: data.scaleType });
				dispatch(
					createAction(actionTypes.UPDATE_SITE_CONFIG, {
						chartView: data.scaleType
					})
				);

				const keys = data.chartSettings.map((item: any) => `${item.symbol}-${item.boerse}-${item.id}`);
				Object.keys(allChartDetails).map((key: string) => {
					if (!keys.includes(key)) {
						delete allChartDetails[key];
					}
				});
				setUpdateChartViewLoading(false);
				if (data.chartSettings.length > 1) {
					navigate(`/materials/chartScreen?timeSpan=${timeSpan || spanObj.span}`, {
						state: {
							advanceChartConfig: data,
							chartSelection: {
								type: data.scaleType === ScaleType.PERCENTAGE_CHANGE ? 'line' : chartType,
								span: spanObj.span
							}
						}
					});
				} else if (chartSettings.length > 1 && data.chartSettings.length === 1) {
					const { boerse, symbol, basis_symbol } = data.chartSettings[0];
					const { span, endDate, startDate } = spanObj;
					const navigationPath = `/materials/futureTable?boerse=${replaceHashtagFromUrl(boerse)}&basis_symbol=${
						basis_symbol !== null ? replaceHashtagFromUrl(basis_symbol) : ''
					}&symbol=${replaceHashtagFromUrl(symbol)}&currency=${defaultCurrency || ''}&timeSpan=${timeSpan || span}`;
					navigate(navigationPath, {
						state: location.pathname.includes('chartScreen')
							? {
									advanceChartConfig: data,
									chartSelection: {
										currency: data.chartSettings[0].currency.value,
										indicator: data.chartSettings[0]?.indicators || [],
										span: span,
										type: data.scaleType === ScaleType.PERCENTAGE_CHANGE ? 'line' : chartType,
										chartColor: data.chartSettings[0]?.color || chartColor,
										unit: data.chartSettings[0]?.unit.value || '',
										history: data.chartSettings[0]?.history.value || '',
										axis: data.chartSettings[0]?.axis.value || 'right',
										customChartSpan: {
											von: startDate,
											bis: endDate
										}
									}
							  }
							: {}
					});
					setIsChartSettingUpdate(true);
				} else if (chartSettings.length === 1 && data.chartSettings.length === 1) {
					setChartSettings(data.chartSettings);
					// setSpanObj(spanObj);
					setChartType(data.scaleType === ScaleType.PERCENTAGE_CHANGE ? 'line' : chartType);
					setIsChartSettingUpdate(true);
				}
				setScaleType(data.scaleType);
				setChartColor(data.chartSettings[0].color);
			})
			.catch((error) => {
				setUpdateChartViewLoading(false);
				console.error(error);
			});
	};
	const { isLeftAxis, isRightAxis } = useMemo(
		() => ({
			isLeftAxis: filter(chartSettings, { axis: { value: ChartAxisPosition.LEFT_AXIS } }).length > 0,
			isRightAxis: filter(chartSettings, { axis: { value: ChartAxisPosition.RIGHT_AXIS } }).length > 0
		}),
		[chartSettings]
	);

	useEffect(() => {
		if (!isEmpty(chartData)) {
			const chartDate = new Date(chartData[chartData.length - 1].date);
			const currentDate = new Date();
			if (chartDate > currentDate) {
				setIsVisibleForecastNotation(true);
				setIsVisiblePdfModuleNotation(true);
			} else {
				setIsVisiblePdfModuleNotation(false);
				setIsVisibleForecastNotation(false);
			}
		}
	}, [chartData, isVisibleForecastNotation, isVisiblePdfModuleNotation]);

	return (
		<>
			<div className='futuretable-main'>
				<div className={(isTableOpen && 'd-flex') || ''}>
					{!location.pathname.includes('chartScreen') && (
						<MaterialTables
							chartData={chartData}
							chartDetails={allChartDetails[`${chartSettings[0]?.symbol || symbol}-${chartSettings[0]?.boerse || boerse}-0`]}
							currency={currency as string}
							futureTableData={futureTableData}
							handleOnRowClick={handleOnRowClick}
							loading={futureTableLoading}
							materialTableLoading={loading}
							setIsTableOpen={setIsTableOpen}
							isTableOpen={isTableOpen}
							setIsChartSettingUpdate={() => setIsChartSettingUpdate(true)}
							dateMatchKey={`cv${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`}
						/>
					)}
					<div className={isTableOpen && !location.pathname.includes('chartScreen') ? `width--70 chart_screen` : 'full-width'}>
						{chartSettings.map((header, index) => {
							const { symbol, boerse, id, color, indicators, currency } = header;
							return (
								<ChartHeader
									key={`${symbol}-${boerse}-${id}`}
									color={color}
									chartDetails={allChartDetails[`${symbol}-${boerse}-${index}`] || {}}
									indicators={indicators}
									headerIndex={index}
									chartSettings={chartSettings}
									scaleType={scaleType}
									setChartSettings={setChartSettings}
									chartColor={chartColor}
									chartType={chartType}
									currency={currency.value}
									spanObj={spanObj}
									setChartData={setChartData}
									setAllChartDetails={setAllChartDetails}
									chartId={id}
									setIsChartSettingUpdate={() => setIsChartSettingUpdate(true)}
								/>
							);
						})}
						<div className='chart mt-40 bg-color--white'>
							{loading && (
								<div className='d-flex justify-content-space-between align-items-center chart-indicators pt-24 mb-10'>
									<div className='d-flex align-items-center gap--10 indicator-section-1'>
										<Skeleton height={24} width={24} />
										<div className='d-flex align-items-center gap--10'>
											<Skeleton height={24} width={200} />
											<Skeleton height={24} width={200} />
											<Skeleton height={24} width={200} />
										</div>
									</div>
								</div>
							)}
							{!isEmpty(allChartDetails) &&
								!loading &&
								!isEmpty(Object.values(allChartDetails).filter((item) => item.disallowed === 0)) && (
									<ChartConfigurationDropdowns
										chartType={chartType}
										handleChartType={handleChartType}
										getComparativeChartData={getComparativeChartData}
										handleSpan={handleSpan}
										spanObj={spanObj}
										setAction={setAction}
										disabledSearch={loading || priceListLoading}
										chartDetails={
											allChartDetails[`${updatedChartSettings[0]?.symbol}-${updatedChartSettings[0]?.boerse}-0`] || {}
										}
										setIsChartSettingUpdate={() => setIsChartSettingUpdate(true)}
										setSpanObj={setSpanObj}
										allChartDetails={allChartDetails}
									/>
								)}
							<div
								className={`chart-wrapper ${isLeftAxis && isRightAxis ? 'left-right-axis' : isRightAxis ? 'right-axis' : ''}`}
								id={`chart-container-main-${updatedChartSettings[0]?.symbol}-0`}
								onMouseEnter={onMouseEnter}
								onMouseLeave={onMouseLeave}
							>
								{loading && <Loader />}
								{!loading &&
									!isEmpty(updatedAllChartDetails) &&
									isEmpty(Object.values(updatedAllChartDetails).filter((item) => item.disallowed === 0)) && (
										<div className='d-flex justify-content-center align-items-center locked-chart' style={{ height: '550px' }} />
									)}
								{isEmpty(chartData) &&
									!loading &&
									!isEmpty(updatedAllChartDetails) &&
									every(Object.values(updatedAllChartDetails), ['disallowed', 0]) && <EmptyDataContainer />}
								{!isEmpty(chartData) &&
									!isEmpty(updatedChartSettings) &&
									updatedAllChartDetails[`${updatedChartSettings[0].symbol}-${updatedChartSettings[0].boerse}-0`] &&
									!loading &&
									!isEmpty(Object.values(updatedAllChartDetails).filter((item) => item.disallowed === 0)) && (
										<StockChart
											chartData={chartData}
											chartType={chartType}
											showZoomButton={true}
											loading={loading}
											chartDetails={
												updatedAllChartDetails[`${updatedChartSettings[0].symbol}-${updatedChartSettings[0].boerse}-0`]
											}
											cardIndex={0}
											chartDesignConfig={{
												margin: {
													left: defineChartMargins(updatedChartSettings, false)?.left as number,
													right: defineChartMargins(updatedChartSettings, false)?.right as number,
													top: 10,
													bottom: 40
												},
												fontSize: 12,
												rectWidth: 55,
												rectHeight: 20
											}}
											spanObj={spanObj}
											setChartColor={setChartColor}
											chartName='main'
											chartSettings={updatedChartSettings}
											scaleType={scaleType}
											isIntraday={isIntraday}
											allChartDetails={updatedAllChartDetails}
											barWidth={barWidth}
											setBarWidth={setBarWidth}
										/>
									)}
							</div>
						</div>
					</div>
				</div>
				{isVisibleForecastNotation && chartSettings.length < 2 && (
					<div className='d-flex justify-content-end mt-10'>
						<div className='d-flex gap--10 chart-forecast-indicator__mapper'>
							<label className='form-control-forecast font--13px font-weight--500'>
								<div className='circle' style={{ border: `2px solid ${chartSettings[0]?.color || 'white'}` }}>
									<div className='inner-circle' style={{ background: `${chartSettings[0]?.color || 'white'}` }} />
								</div>
								<Translate text={localizationConstants.actualData} />
							</label>

							<label className='form-control-forecast font--13px font-weight--500'>
								<div className='circle' style={{ border: `2px solid #FF9100` }}>
									<div className='inner-circle' style={{ background: '#FF9100' }} />
								</div>
								<Translate text={localizationConstants.matflixxForecast} />
							</label>
						</div>
					</div>
				)}
				{!location.pathname.includes('chartScreen') &&
					!isEmpty(allChartDetails[`${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`]) && (
						<ChartAdditionalInfo
							chartData={chartData}
							futureTableData={futureTableData}
							chartDetails={allChartDetails[`${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`]}
							spanObj={spanObj}
						/>
					)}
				<div className='add-widget'>
					{chartSettings.length > 0 &&
						!isEmpty(allChartDetails) &&
						!isEmpty(allChartDetails[`${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`]) && (
							<ExportMaterialDataButtons
								chartData={chartData}
								chartDetails={allChartDetails[`${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`]}
								chartSelection={{
									currency: chartSettings[0].currency.value,
									indicator: chartSettings[0]?.indicators || [],
									span: spanObj.span,
									type: chartType,
									chartColor: chartSettings[0]?.color || chartColor,
									unit: chartSettings[0]?.unit.value || '',
									history: chartSettings[0]?.history.value || '',
									axis: chartSettings[0]?.axis.value || 'right',
									chartView: scaleType
								}}
								id={`chart-container-main-${updatedChartSettings[0]?.symbol}-0`}
								action={action}
								setAction={setAction}
								symbol={symbol}
								comparativeChartIndicator={chartSettings.slice(1)}
								customChartSpan={{
									bis: spanObj.endDate || '',
									von: spanObj.startDate || ''
								}}
								strokeColors={strokeColors}
								chartSettings={chartSettings}
								scaleType={scaleType}
							/>
						)}
				</div>
				{chartSettings.length === 1 &&
					!isEmpty(allChartDetails) &&
					!isEmpty(allChartDetails[`${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`]) && (
						<PermissionManager permission={adminPermissionMapping.superDataUserAdmin}>
							<AdminExportButtons
								basis_symbol={basis_symbol}
								boerse={chartSettings[0]?.boerse}
								chartData={chartData}
								chartDetails={allChartDetails[`${chartSettings[0]?.symbol}-${chartSettings[0]?.boerse}-0`]}
								symbol={chartSettings[0].symbol}
								setPriceListLoading={setPriceListLoading}
								isIntraday={isIntraday}
							/>
						</PermissionManager>
					)}
			</div>
			<ChartSettingsPopup
				chartSettings={chartSettings}
				isVisible={action === 'chartSettings'}
				setAction={setAction}
				scaleType={scaleType}
				handleOnSubmit={handleAdvanceChartConfiguration}
				updateChartViewLoading={updateChartViewLoading}
				chartType={chartType}
				chartDetails={allChartDetails}
			/>
		</>
	);
};

export default ChartScreenContainer;
