import moment from 'moment';
import React, { useState } from 'react';

import Button from 'shared/components/form/button';
import CustomModal from 'shared/components/modal/modal';
import { API_CONFIG } from 'shared/constants/constants';
import HttpService from 'shared/services/http.service';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

import { IChartData, IChartDetails } from '../interface/futureTable';
import ExportChartImages from './exportChartImages';
import { getStartDateForExport } from 'shared/util/utility';

interface IProps {
	chartDetails: IChartDetails;
	span: number | string;
	setAction: (action: string) => void;
	customChartSpan: {
		von: string;
		bis: string;
	};
	chartType: string;
	id: string;
	setIsExcelDownloadOpen: (action: boolean) => void;
	isExcelDownloadOpen: boolean;
	chartData: IChartData[];
}

const ExportChartPopup: React.FC<IProps> = (props) => {
	const { customChartSpan, span, chartDetails, setAction, chartType, id, setIsExcelDownloadOpen, isExcelDownloadOpen, chartData } = props;
	const [isLoading, setIsLoading] = useState(false);

	const exportHistroyAsCSVFucntion = () => {
		let endDate = moment(chartDetails.kurszeit).format('yyyy-MM-DD HH:mm:ss');
		let startDate = getStartDateForExport(span as string, endDate, chartData);
		if (customChartSpan.bis !== '' && customChartSpan.von !== '') {
			startDate = moment(customChartSpan.von).format('yyyy-MM-DD HH:mm:ss');
			endDate = moment(customChartSpan.bis).format('yyyy-MM-DD HH:mm:ss');
		}

		const params = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse,
			basis_symbol: chartDetails.basis_symbol,
			waehrung: chartDetails.waehrung,
			groupby: 'daily',
			format: 'csv',
			start_datum: startDate,
			end_datum: endDate
		};
		setIsLoading(true);
		HttpService.post(`${API_CONFIG.path.exportCourseHistory} `, params)
			.then((response) => {
				if (response) {
					const csvData = [] as string[][];
					response.kurshistorie.forEach((value: any) => csvData.push([value.datum, value.letzter]));
					let csv = 'Datum        ,Letzer    \n';
					csvData.forEach(function (row) {
						csv += row.join(',');
						csv += '\n';
					});
					const fileName = `${response.stammdaten.name}_${response.stammdaten.waehrung}.csv`;
					const hiddenElement = document.createElement('a');
					hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
					hiddenElement.target = '_blank';
					hiddenElement.download = fileName;
					hiddenElement.click();
				}
				setAction('');
				setIsExcelDownloadOpen(false);
			})
			.catch((err) => {
				setIsLoading(false);
				console.error(err);
			});
	};

	return (
		<CustomModal show={true} className='download-excel-popup' handleClose={() => setAction('')}>
			<h2 className='modal-title'>
				<Translate text={localizationConstants.exportData} />
			</h2>

			<h2 className='modal-title font--16px line-height--500'>
				<Translate text={localizationConstants.selectFileFormat} />
			</h2>
			<Button
				btnType='primary'
				className='mr-10 padding-left-right--16px font-weight--500 line-height--20px'
				onClick={() => setIsExcelDownloadOpen(!isExcelDownloadOpen)}
			>
				<Translate text={localizationConstants.exportDataAsExcel} />
			</Button>
			<Button
				btnType='primary'
				className=' padding-left-right--16px font-weight--500 line-height--20px'
				onClick={exportHistroyAsCSVFucntion}
				loading={isLoading}
			>
				<Translate text={localizationConstants.exportHistoryAsCSV} />
			</Button>
			<h2 className='modal-title font--16px line-height--500 mt-20'>
				<Translate text={localizationConstants.graphicDownload} />
			</h2>
			<ExportChartImages chartDetails={chartDetails} chartType={chartType} id={id} />
		</CustomModal>
	);
};

export default ExportChartPopup;
