import { SiteConfigState, Action } from 'shared/interface';
import authService from 'shared/services/auth.service';
import * as actionTypes from 'store/action-types';
import AuthService from 'shared/services/auth.service';
import { IMatfoxxConfig } from 'features/login/interface/login';
import { browserLanguage } from 'shared/constants/constants';

const initialMatfoxxVconfig = {
	notifications: 0,
	notification_period: 0,
	from: null,
	until: null,
	filter_notification_joke: 0,
	filter_notification_offer: 0,
	view: 0
};

const siteConfigReducer = (
	state: SiteConfigState = {
		admin: authService.getAuthData()?.admin || [],
		language: localStorage.getItem('lang') || browserLanguage || 'de',
		dateFormat: localStorage.getItem('dateFormate') || 'DD/MM/YYYY',
		matfoxxConfig: (AuthService.getMatfoxxSettings() as IMatfoxxConfig) || initialMatfoxxVconfig,
		recordPerPage: Number(localStorage.getItem('recordPerPage')) || 50,
		isTakeOver: localStorage.getItem('isTakeOver') === '1' || false,
		isForgotPassword: false,
		sessionExpireMessage: '',
		chartView:
			(AuthService.getAuthData()?.chart_view && AuthService.getAuthData()?.chart_view !== '' && AuthService.getAuthData()?.chart_view) ||
			'price'
	},
	action: Action
): SiteConfigState => {
	switch (action.type) {
		case actionTypes.UPDATE_SITE_CONFIG:
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default siteConfigReducer;
