import { FormikValues } from 'formik';
import { Tooltip } from 'react-tooltip';

import { isEmpty, size } from 'lodash';
import Button from 'shared/components/form/button';
import { notify } from 'shared/components/notification/notification';
import { QuestionMarkIcon } from 'shared/icons/icon';
import { ChartAxisPosition, ScaleType } from 'shared/interface/enums';
import i18n from 'shared/util/localization';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';

interface IAdvanceSettingsPopupFooter {
	setFieldValue: (name: string, value: any) => void;
	dirty: boolean;
	setAction: (action: string) => void;
	handleSubmit: () => void;
	values: FormikValues;
	updateChartViewLoading: boolean;
}

const AdvanceSettingsPopupFooter: React.FC<IAdvanceSettingsPopupFooter> = (props) => {
	const { t } = i18n;
	const { setFieldValue, dirty, values, handleSubmit, setAction, updateChartViewLoading } = props;
	const validate = values.chartSettings.filter((item: any) => item.history.value !== '');
	return (
		<div className='advance-settings-footer pt--36 d-flex justify-content-space-between align-items-center'>
			<h2 className='font-weight--700 line-height--20px font--15px no-margin'>
				<Translate text={localizationConstants.advancedSettings} />
			</h2>
			<div className='checkbox-container d-flex'>
				<div className='radio-input-wrapper'>
					<input
						id={`scaleType${ScaleType.PRICE_AXIS}`}
						type='radio'
						name='scaleType'
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							const value = event.target.value;
							setFieldValue('scaleType', value);
							if (ScaleType.PRICE_AXIS === event.target.value) {
								values.chartSettings.map((item: any, index: number) => {
									setFieldValue(`chartSettings[${index}].axis`, {
										value: ChartAxisPosition.RIGHT_AXIS,
										label: `${t(localizationConstants.right)}`
									});
								});
							}
						}}
						value={ScaleType.PRICE_AXIS}
						checked={ScaleType.PRICE_AXIS === values.scaleType}
					/>
					<label
						className={`pointer mr-26 text--secondary ${
							ScaleType.PRICE_AXIS === values.scaleType ? 'checked' : ''
						} font--12px line-height--17px font-weight--400 text--dark-blue`}
						htmlFor={`scaleType${ScaleType.PRICE_AXIS}`}
					>
						<Translate text={localizationConstants.default} />

						<QuestionMarkIcon className='position--absolute' id='defaultRadioTooltip' />
						<Tooltip
							anchorId={`defaultRadioTooltip`}
							place='top'
							className='no-padding duplicate-chart-tooltip'
							content={`${t(localizationConstants.defaultTooltipMessage)}`}
						/>
					</label>
				</div>
				<div className='radio-input-wrapper'>
					<input
						id={`scaleType${ScaleType.PERCENTAGE_CHANGE}`}
						type='radio'
						name='scaleType'
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							setFieldValue('scaleType', event.target.value);
							if (ScaleType.PERCENTAGE_CHANGE === event.target.value) {
								values.chartSettings.map((item: any, index: number) => {
									setFieldValue(`chartSettings[${index}].axis`, {
										value: ChartAxisPosition.LEFT_AXIS,
										label: `${t(localizationConstants.left)}`
									});
								});
							}
						}}
						value={ScaleType.PERCENTAGE_CHANGE}
						checked={ScaleType.PERCENTAGE_CHANGE === values.scaleType}
					/>
					<label
						className={`pointer mr-26 text--secondary ${
							ScaleType.PERCENTAGE_CHANGE === values.scaleType ? 'checked' : ''
						} font--12px line-height--17px font-weight--400 text--dark-blue`}
						htmlFor={`scaleType${ScaleType.PERCENTAGE_CHANGE}`}
					>
						<Translate text={localizationConstants.change} />

						<QuestionMarkIcon className='position--absolute' id='changeRadioTooltip' />
						<Tooltip
							anchorId={`changeRadioTooltip`}
							place='top'
							className='no-padding duplicate-chart-tooltip'
							content={`${t(localizationConstants.changeToolTipMessage)}`}
						/>
					</label>
				</div>
				<div className='radio-input-wrapper'>
					<input
						id={`scaleType${ScaleType.LOGARITHAMIC_AXIS}`}
						type='radio'
						name='scaleType'
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							const value = event.target.value;
							setFieldValue('scaleType', value);
							if (ScaleType.LOGARITHAMIC_AXIS === event.target.value) {
								values.chartSettings.map((item: any, index: number) => {
									setFieldValue(`chartSettings[${index}].axis`, {
										value: ChartAxisPosition.RIGHT_AXIS,
										label: `${t(localizationConstants.right)}`
									});
								});
							}
						}}
						value={ScaleType.LOGARITHAMIC_AXIS}
						checked={ScaleType.LOGARITHAMIC_AXIS === values.scaleType}
					/>
					<label
						className={`pointer mr-26 text--secondary ${
							ScaleType.LOGARITHAMIC_AXIS === values.scaleType ? 'checked' : ''
						} font--12px line-height--17px font-weight--400 text--dark-blue`}
						htmlFor={`scaleType${ScaleType.LOGARITHAMIC_AXIS}`}
					>
						<Translate text={localizationConstants.logarithmicYAxis} />

						<QuestionMarkIcon className='position--absolute' id='logarithmToolTipMessage' />
						<Tooltip
							anchorId={`logarithmToolTipMessage`}
							place='top'
							className='no-padding duplicate-chart-tooltip'
							content={`${t(localizationConstants.LogarithmicYAxisTooltipMessage)}`}
						/>
					</label>
				</div>
			</div>
			<div className='btn-wrapper d-flex'>
				<Button
					btnType='secondary'
					type='button'
					onClick={() => setAction('')}
					className='d-flex button button-size--medium justify-content-center align-items-center br--6px p--6 no-border'
				>
					<Translate text={localizationConstants.cancel} />
				</Button>
				<Button
					btnType='primary'
					type='button'
					onClick={() =>
						!isEmpty(validate) && size(validate) !== size(values.chartSettings)
							? notify(t(localizationConstants.pleaseSelectAllHistoryDropdown))
							: handleSubmit()
					}
					disabled={!dirty}
					loading={updateChartViewLoading}
					className='d-flex button button-size--medium justify-content-center align-items-center br--6px p--6'
				>
					<Translate text={localizationConstants.submit} />
				</Button>
			</div>
		</div>
	);
};

export default AdvanceSettingsPopupFooter;
