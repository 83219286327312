import React, { useCallback, useEffect, useState } from 'react';
import { CustomForm } from 'shared/components/form/form';
import CustomModal from 'shared/components/modal/modal';
import localizationConstants from 'shared/util/translation/constants';
import { Translate } from 'shared/util/translation/translate';
import { customNumberFormatter, formatDate } from 'shared/util/utility';
import { IChartDetails } from '../interface/futureTable';
import { FieldConfig } from 'shared/components/form/inputTypes';
import i18n from 'shared/util/localization';
import { API_CONFIG, DATE_PLACEHOLDER_MAPPER } from 'shared/constants/constants';
import { useSelector } from 'react-redux';
import { State } from 'shared/interface';
import httpService from 'shared/services/http.service';
import { useQueryParams } from 'shared/hooks/useQueryParams';
import { Alert } from 'shared/interface/enums';
import { notify } from 'shared/components/notification/notification';
import size from 'lodash/size';
import { FormikValues } from 'formik';
import { useNavigate } from 'react-router-dom';

interface IProps {
	boerse: string | null;
	symbol: string | null;
	chartDetails: IChartDetails;
	setAction: (action: string) => void;
}

interface IMaterialAlertsDetails {
	symbol: string;
	boerse: string;
	name: string;
	name_deu: string;
	name_eng: string;
	waehrung: string;
	hauptkurs: number;
}

const SetupPriceAlert: React.FC<IProps> = (props) => {
	const { t } = i18n;
	const { symbol: symbolParam, boerse: boerseParam } = useQueryParams();

	const { language, dateFormat } = useSelector((state: State) => state.siteConfig);
	const [alertDefaultDetails, setAlertDefaultDetails] = useState<IMaterialAlertsDetails>({} as IMaterialAlertsDetails);
	const { chartDetails, boerse, symbol, setAction } = props;
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const getMaterialDetails = useCallback(() => {
		const payload = {
			boerse: boerseParam,
			symbol: symbolParam
		};
		httpService
			.get(`${API_CONFIG.path.getMaterialDetails}`, payload)
			.then((response) => {
				setAlertDefaultDetails(response);
			})
			.catch((err) => {
				console.error(err);
			});
	}, [boerseParam, symbolParam]);

	const fieldConfig: FieldConfig[] = [
		{
			type: 'dropdown',
			label: `${t(localizationConstants.reportIf)}`,
			placeHolder: `${t(localizationConstants.addAlertDropDownPlaceholder)}`,
			name: 'report',
			otherOptions: {
				dropDownOptions: [
					{ label: `${t(localizationConstants.courseExceeded)}`, value: Alert.COURSE_EXCEEDED },
					{ label: `${t(localizationConstants.courseUndershot)}`, value: Alert.COURSE_UNDERSHOT },
					{ label: `${t(localizationConstants.positiveDifference)}`, value: Alert.POSITIVE_DIFFERENCE },
					{ label: `${t(localizationConstants.negativeDifference)}`, value: Alert.NEGATIVE_DIFFERENCE }
				]
			}
		},
		{
			type: 'number',
			label: `${t(localizationConstants.comparativeValue)}`,
			name: 'comparisonValue',
			placeHolder: '123'
		},
		{
			type: 'date',
			label: `${t(localizationConstants.validUntill)}`,
			name: 'doe',
			placeHolder: DATE_PLACEHOLDER_MAPPER(language)[dateFormat]
		}
	];

	const alertPlaceholder: { [key: string]: string } = {
		[`${Alert.KURS_UNDERSHOT}`]: `${t(localizationConstants.courseUndershot)}`,
		[`${Alert.KURS_EXCEEDED}`]: `${t(localizationConstants.courseExceeded)}`,
		[`${Alert.NEGATIVE_DIFFERENCE_GERMEN}`]: `${t(localizationConstants.negativeDifference)}`,
		[`${Alert.POSITIVE_DIFFERENCE_GERMEN}`]: `${t(localizationConstants.positiveDifference)}`
	};

	const postAlertData = useCallback(
		(values: FormikValues) => {
			let reportValue = '';
			const pathMapper: { [key: string]: string } = {
				max: `${Alert.KURS_EXCEEDED}`,
				min: `${Alert.KURS_UNDERSHOT}`,
				[`${Alert.NEGATIVE_DIFFERENCE}`]: `${Alert.OTHER_ALERTS}`,
				[`${Alert.POSITIVE_DIFFERENCE}`]: `${Alert.OTHER_ALERTS}`
			};
			if (values.report.value === Alert.COURSE_EXCEEDED) {
				reportValue = 'max';
			} else if (values.report.value === Alert.COURSE_UNDERSHOT) {
				reportValue = 'min';
			} else {
				reportValue = values.report.value;
			}
			let params: any = {
				art: reportValue,
				wert: parseFloat(values.comparisonValue),
				boerse: boerse,
				symbol: symbol
			};
			if (values.doe !== '') {
				const date = new Date(values.doe).toISOString();
				params = { ...params, gueltig_bis: formatDate(date, 'YYYY-MM-DD') };
			}
			if (params.art === '') {
				notify(t(localizationConstants.selectFromTheDropDown), 'error', { id: 'selectToDropDown' });
			} else if (values.comparisonValue === '') {
				notify(`${t(localizationConstants.comparisionValueShouldNotBeEmpty)}`, 'error', { id: 'emptyValue' });
			} else if (params.art === 'max' && params.wert <= alertDefaultDetails.hauptkurs) {
				notify(`${t(localizationConstants.enterValuesGreaterThan)} ${alertDefaultDetails.hauptkurs}`, 'error', {
					id: 'valueGraterWithDefault'
				});
			} else if (params.art === 'min' && params.wert >= alertDefaultDetails.hauptkurs) {
				notify(`${t(localizationConstants.enterValuesLessThan)} ${alertDefaultDetails.hauptkurs}`, 'error', { id: 'valueLessWithDefault' });
			} else if (
				(params.art === Alert.POSITIVE_DIFFERENCE || params.art === Alert.NEGATIVE_DIFFERENCE) &&
				(params.wert < 0 || params.wert > 100)
			) {
				notify(t(localizationConstants.comparisonValues), 'error', { id: 'valueCompare' });
			} else if (params.wert === 0) {
				notify(t(localizationConstants.comparisonValueGreaterThanZero), 'error', { id: 'compareWithZero' });
			} else if (size(params.wert.toString()) > 15) {
				notify(t(localizationConstants.numberIsTooBig), 'error', { id: 'valueGrater' });
			} else {
				setLoading(true);
				httpService
					.post(`${API_CONFIG.path.setMaterialAlert}`, params)
					.then(() => {
						setAction('');
						navigate(`/alert/material/${pathMapper[reportValue]}`);
						setLoading(false);
					})
					.catch((err) => {
						setLoading(false);
						console.error(err);
					});
			}
		},
		[alertDefaultDetails.hauptkurs, boerse, setAction, symbol, t]
	);

	useEffect(() => {
		getMaterialDetails();
	}, []);

	return (
		<CustomModal handleClose={() => setAction('')} show={true} className='course-list-popup'>
			<h2 className='modal-title'>
				<Translate text={localizationConstants.priceAlert} />
			</h2>
			<h3 className='mb-8 font-weight--500 font--16px line-height--22px'>
				{(language === 'en' && chartDetails.name_eng !== '' && chartDetails.name_eng) || chartDetails.name}
			</h3>
			<h5 className='font-weight--400 font--14px line-height--20px'>
				<Translate text={localizationConstants.currentPrice} />:
				<span className='font-weight--500 font--16px line-height--22px ml-5'>
					{customNumberFormatter(alertDefaultDetails.hauptkurs, chartDetails.nkstellen)}
				</span>
			</h5>
			<h5 className='font-weight--400 font--14px line-height--20px'>
				<Translate text={localizationConstants.alertCurrency} />:
				<span className='font-weight--500 font--16px line-height--22px ml-5'>{alertDefaultDetails.waehrung}</span>
			</h5>
			<CustomForm
				onSubmit={postAlertData}
				loading={loading}
				fieldConfig={fieldConfig}
				showLabels
				showMinDate={true}
				initialValues={{
					report: 'course exceeded',
					email: ''
				}}
				dynamicPlaceholder={{
					dependent: 'report',
					self: 'comparisonValue',
					placeHolderMapper: alertPlaceholder
				}}
				submitBtnClassName={'popup-submit font--14px font-weight--400 line-height--20px full-width mt-32'}
				submitBtnText={`${t(localizationConstants.add)}`}
			/>
		</CustomModal>
	);
};

export default SetupPriceAlert;
