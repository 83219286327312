import React, { Fragment, useCallback, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import isEmpty from 'lodash/isEmpty';
import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';
import moment from 'moment';

import Button from 'shared/components/form/button';
import { ScaleType } from 'shared/interface/enums';
import HttpService from 'shared/services/http.service';
import authService from 'shared/services/auth.service';
import CustomModal from 'shared/components/modal/modal';
import { API_CONFIG } from 'shared/constants/constants';
import { Translate } from 'shared/util/translation/translate';
import { notify } from 'shared/components/notification/notification';
import localizationConstants from 'shared/util/translation/constants';
import { calculateInicatorAverages, getChartDataBasedOnGroupBy, getStartDateForExport } from 'shared/util/utility';
import { getChartDataBasedOnIndicators, getIndicatorsWindowSize } from 'features/futuretable/util/chartUtility';

import { IIndicatorConfig } from './stockChart';
import { IChartDetails, IChartSettingsType, ICompareChartIndicators, ISearchMaterialData } from '../interface/futureTable';
import { t } from 'i18next';
import { QuestionMarkIcon } from 'shared/icons/icon';
import { Tooltip } from 'react-tooltip';

interface IProps {
	setIsExcelDownloadOpen: (action: boolean) => void;
	setAction: (action: string) => void;
	id: string;
	chartType: string;
	span: number | string;
	chartDetails: IChartDetails;
	symbol: string;
	customChartSpan: {
		von: string;
		bis: string;
	};
	comparativeChartIndicator: ICompareChartIndicators[] | ISearchMaterialData[] | IChartSettingsType[];
	chartSettings: IChartSettingsType[];
	scaleType: string;
}

const AdvanceExportChartDataPopup: React.FC<IProps> = (props) => {
	const {
		customChartSpan,
		span,
		chartDetails,
		setAction,
		chartType,
		id,
		setIsExcelDownloadOpen,
		comparativeChartIndicator,
		chartSettings,
		scaleType
	} = props;

	const [courseGroup, setCourseGroup] = useState('');
	const [loading, setLoading] = useState(false);
	const [submitChartConfigLoading, setSubmitChartConfigLoading] = useState(false);
	const [exportFormat, setExportFormat] = useState<string>('standard');
	const [exportSorting, setExportSorting] = useState<string>('ASC');
	const [selectedUuid, setSelectedUuid] = useState('');
	const [isProcessCompleted, setIsProcessCompleted] = useState(false);
	const token = authService.getAccessToken();

	const getExportIndicatorData = useCallback(
		async (isIntraday: number, group: string) => {
			const promises = chartSettings.map(async (chart) => {
				let endDate = moment(chartDetails.kurszeit).format('yyyy-MM-DD HH:mm:ss');
				let startDate = span === 'max' ? '' : getStartDateForExport(span as string, endDate, []);
				if (customChartSpan.bis !== '' && customChartSpan.von !== '') {
					startDate = moment(customChartSpan.von).format('yyyy-MM-DD HH:mm:ss');
					endDate = moment(customChartSpan.bis).format('yyyy-MM-DD HH:mm:ss');
				}
				if (!isEmpty(chart.indicators)) {
					const payload = {
						symbol: chart.symbol,
						boerse: chart.boerse,
						waehrung: chart.currency.value,
						unit: chart.unit.value,
						year: parseInt(chart.history.value) || '',
						is_intraday: isIntraday,
						start: span === 'max' || chartType === 'bar' ? '' : moment(startDate).format('yyyy-MM-DD HH:mm:ss'),
						end: endDate
					};
					try {
						const response = await HttpService.get(API_CONFIG.path.getChartData, payload);
						const indicatorsFunctionNames = [] as IIndicatorConfig[];
						if (chart.indicators.length > 0) {
							chart.indicators.map((indi) => {
								indicatorsFunctionNames.push({
									indicatorKeyName: `${indi.value}-${chart.symbol}-${chart.boerse}-${chart?.id || ''}`,
									keyName: scaleType === ScaleType.PERCENTAGE_CHANGE ? 'percentage' : 'letzter',
									windowSize: getIndicatorsWindowSize(indi),
									value: indi.value
								});
							});
						}
						const chartDataBasedOnGroupBy = getChartDataBasedOnGroupBy(group, calculateInicatorAverages([...response.kurshistorie]));
						const calculatedData = getChartDataBasedOnIndicators(chartDataBasedOnGroupBy, indicatorsFunctionNames, scaleType);
						const newArray = calculatedData
							.filter((obj) => {
								const timestampRegex = /\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}/;
								if (isIntraday === 1) {
									return timestampRegex.test(obj['datum']);
								} else {
									return !timestampRegex.test(obj['datum']);
								}
							})
							.map(
								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								({ brief, erster, geld, hoch, letzter, letzter_, oi, percentage, settlement, tief, umsatz, count, ...rest }) => rest
							);
						return {
							key: `${chart.symbol}-${chart.boerse}-${chart.id}`,
							value: newArray
						};
					} catch (error) {
						console.error(error);
						return null;
					}
				} else {
					return {
						key: `${chart.symbol}-${chart.boerse}-${chart.id}`,
						value: []
					};
				}
			});
			const results = await Promise.all(promises);
			const newChartDataObject = results.reduce((acc: any, result) => {
				if (result) {
					acc[result.key] = result.value;
				}
				return acc;
			}, {});

			return newChartDataObject;
		},
		[chartSettings, scaleType, chartDetails.kurszeit, customChartSpan.bis, customChartSpan.von, span]
	);

	const deleteTempUuid = useCallback(() => {
		HttpService.deleteRequest(`${API_CONFIG.path.getChartMasterData}/${selectedUuid}`).then().catch();
	}, [selectedUuid]);

	const advanceExportCourseHistory = useCallback(
		(courseGroup: string, indicatorData: any, chartImageConfig: any) => {
			let endDate = moment(chartDetails.kurszeit).format('yyyy-MM-DD HH:mm:ss');
			let startDate = span === 'max' || chartType === 'bar' ? '' : getStartDateForExport(span as string, endDate, []);
			if (customChartSpan.bis !== '' && customChartSpan.von !== '') {
				startDate = moment(customChartSpan.von).format('yyyy-MM-DD HH:mm:ss');
				endDate = moment(customChartSpan.bis).format('yyyy-MM-DD HH:mm:ss');
			}
			const data = chartSettings.map((item) => {
				return {
					symbol: item.symbol,
					boerse: item.boerse,
					basis_symbol: item.basis_symbol,
					chart: {
						dpi: 1,
						height: 506,
						width: 1141
					},
					indicatorData: indicatorData[`${item.symbol}-${item.boerse}-${item.id}`],
					indicatorValues: item.indicators,
					currency: item.currency.value,
					unit: item.unit.value,
					historyYear: item.history.value
				};
			});
			const params = {
				data: {
					chartData: data,
					chartImageConfig: chartImageConfig,
					format: 'xlsx',
					groupby: courseGroup,
					view_start: startDate !== '' ? moment(startDate).format('yyyy-MM-DD HH:mm:ss') : startDate,
					view_end: endDate,
					order: exportSorting,
					isHistoryEnabled: !isEmpty(chartSettings.filter((item) => item.history.value !== '')),
					isPercentageEnabled: scaleType === ScaleType.PERCENTAGE_CHANGE
				}
			};
			HttpService.put(`${API_CONFIG.path.exportCourseHistoryNew}/${selectedUuid}`, params, { responseType: 'arraybuffer' })
				.then((response) => {
					const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
					saveAs(blob, `${response.headers['content-disposition'].split('=')[1]}`);
					setAction('');
					deleteTempUuid();
					setIsExcelDownloadOpen(false);
					setLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					setIsExcelDownloadOpen(false);
					notify('error', err);
					console.error(err);
				});
		},
		[
			chartDetails.kurszeit,
			span,
			chartType,
			customChartSpan.bis,
			customChartSpan.von,
			chartSettings,
			exportSorting,
			selectedUuid,
			setAction,
			deleteTempUuid,
			setIsExcelDownloadOpen,
			scaleType
		]
	);

	const handleChartImages = useCallback(
		async (exportType: string) => {
			const chartDiv = document.getElementById(id);
			if (chartDiv) {
				if (chartType !== 'bar') {
					chartDiv.querySelector('.react-stockcharts-zoom-button')?.classList.add('hide');
				}
				return await html2canvas(chartDiv).then(function (canvas) {
					const imageData = canvas.toDataURL();
					chartDiv.querySelector('.react-stockcharts-zoom-button')?.classList.remove('hide');
					if (exportType === 'advanced') {
						const payload = {
							masterSheet: imageData
						};
						HttpService.put(`${API_CONFIG.path.getChartMasterData}/${selectedUuid}`, payload).then().catch();
					}
					return {
						masterImage: imageData,
						chartImageConfig: {
							height: chartDiv?.offsetHeight,
							width: chartDiv?.offsetWidth
						}
					};
				});
			} else {
				return {
					masterImage: '',
					chartImageConfig: {
						height: '',
						width: ''
					}
				};
			}
		},
		[chartType, id, selectedUuid]
	);

	const handleImages = useCallback(
		async (group: string) => {
			const chartDiv = document.getElementById(id);
			if (chartDiv) {
				if (chartType !== 'bar') {
					chartDiv.querySelector('.react-stockcharts-zoom-button')?.classList.add('hide');
				}
				return await html2canvas(chartDiv).then(function (canvas) {
					const imageData = canvas.toDataURL();
					chartDiv.querySelector('.react-stockcharts-zoom-button')?.classList.remove('hide');
					const payload = {
						masterSheet: imageData
					};
					HttpService.put(`${API_CONFIG.path.getChartMasterData}/${selectedUuid}`, payload)
						.then(async () => {
							const indicatorData = await getExportIndicatorData(
								group === 'intraday' ? 1 : 0,
								group === 'allData' || group === 'intraday' ? 'daily' : group
							);
							advanceExportCourseHistory(group, indicatorData, {
								height: chartDiv?.offsetHeight,
								width: chartDiv?.offsetWidth
							});
						})
						.catch();
				});
			}
		},
		[advanceExportCourseHistory, chartType, getExportIndicatorData, id, selectedUuid]
	);

	const getIsProcessCompletedFlag = useCallback((id: string) => {
		HttpService.get(`${API_CONFIG.path.getChartMasterData}/${id}`)
			.then((response: any) => {
				if (!response.isCompleted) {
					setTimeout(() => {
						getIsProcessCompletedFlag(id);
					}, 2000);
				} else {
					setIsProcessCompleted(response.isCompleted);
				}
			})
			.catch((err) => {
				console.error(err);
			});
	}, []);

	const handleAdvanceExport = useCallback(
		(group: string) => {
			setCourseGroup(group);
			setLoading(true);
		},
		[isProcessCompleted]
	);

	const standardExportCourseHistory = async (courseGroup: string) => {
		setCourseGroup(courseGroup);
		const masterSheetImage = await handleChartImages('standard');
		let endDate = moment(chartDetails.kurszeit).format('yyyy-MM-DD HH:mm:ss');
		let startDate = span === 'max' ? '' : getStartDateForExport(span as string, endDate, []);
		if (customChartSpan.bis !== '' && customChartSpan.von !== '') {
			startDate = moment(customChartSpan.von).format('yyyy-MM-DD HH:mm:ss');
			endDate = moment(customChartSpan.bis).format('yyyy-MM-DD HH:mm:ss');
		}
		const compare_instruments = comparativeChartIndicator.map((ele) => {
			return {
				symbol: ele.symbol,
				boerse: ele.boerse,
				option: {
					label: ele.name,
					label_suffix: `[${(ele as ICompareChartIndicators).currency || (ele as ISearchMaterialData).waehrung} / ${
						(ele as ICompareChartIndicators).suffix || (ele as ISearchMaterialData).einheit
					}]`,
					color: ele.color
				}
			};
		});

		const params = {
			symbol: chartDetails.symbol,
			boerse: chartDetails.boerse,
			format: 'xlsx',
			groupby: courseGroup,
			order: exportSorting,
			chart: {
				dpi: 1,
				height: 506,
				width: 1141,
				base64: masterSheetImage.masterImage
			},
			chartmeta: {
				compare_instruments,
				view_start: startDate,
				view_end: endDate
			},
			waehrung: chartDetails.waehrung
		};
		setLoading(true);
		HttpService.post(`${API_CONFIG.path.exportCourseHistory}`, params, undefined, { responseType: 'arraybuffer' })
			.then((response) => {
				const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
				saveAs(blob, `${response.headers['content-disposition'].split('=')[1]}`);
				setAction('');
				setIsExcelDownloadOpen(false);
			})
			.catch((err) => {
				console.error(err);
			});
	};

	const renderExportButtons = (textKey: string, group: string) => {
		return (
			<span className='position--relative'>
				<Button
					btnType='primary'
					className='button-size--medium'
					onClick={() => {
						exportFormat === 'advanced' ? handleAdvanceExport(group) : standardExportCourseHistory(group);
					}}
					loading={courseGroup === group && loading}
					disabled={courseGroup === group || submitChartConfigLoading}
				>
					<Translate text={localizationConstants[textKey]} />
				</Button>
				<QuestionMarkIcon className='position--absolute bottom-30px' id={`${textKey}Info`} />
				<Tooltip
					anchorId={`${textKey}Info`}
					place='top'
					className='no-padding duplicate-chart-tooltip'
					content={`${t(localizationConstants[`${textKey}Info`])}`}
				/>
			</span>
		);
	};

	const submitChartConfig = () => {
		setSubmitChartConfigLoading(true);
		const id = uuid();
		setSelectedUuid(id);
		const params = {
			id: id,
			chartConfig: { ...{ chartSettings, scaleType }, span: span, customChartSpan: customChartSpan }
		};
		HttpService.post(`${API_CONFIG.path.addAdvanceChartConfig} `, params)
			.then(() => {
				getIsProcessCompletedFlag(id);
				setSubmitChartConfigLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setSubmitChartConfigLoading(false);
			});
	};

	useEffect(() => {
		if (isProcessCompleted) {
			handleImages(courseGroup);
		}
	}, [courseGroup, handleImages, isProcessCompleted]);

	useEffect(() => {
		if (exportFormat === 'advanced' && selectedUuid === '') {
			submitChartConfig();
		}
	}, [exportFormat]);

	return (
		<CustomModal
			show={true}
			className={chartDetails.isIntraday ? `download-excel-popup-big` : `download-excel-popup`}
			handleClose={() => setAction('')}
		>
			<Fragment>
				<h2 className='modal-title'>
					<Translate text={localizationConstants.exportAsExcel} />
				</h2>
				<h2 className='font--16px'>
					<Translate text={localizationConstants.exportFormat} />
				</h2>
				<div className='d-flex align-items-center mt-20 mb-16 gap--10'>
					<div className='radio-input-wrapper'>
						<input
							type='radio'
							id='standard'
							name={'format'}
							checked={exportFormat === 'standard'}
							value={'standard'}
							onChange={({ target: { value } }) => setExportFormat(value)}
						/>
						<label
							className={`pointer mr-26 text--secondary ${exportFormat === 'standard' ? 'checked' : ''}
							font--12px line-height--17px font-weight--400 text--dark-blue`}
							htmlFor={'standard'}
						>
							<span className='position--relative'>
								<Translate text={localizationConstants.standard} />
								<QuestionMarkIcon className='position--absolute ml-5' id={'standardInfo'} />
								<Tooltip
									anchorId={'standardInfo'}
									place='top'
									className='no-padding duplicate-chart-tooltip'
									content={`${t(localizationConstants.standardInfo)}`}
								/>
							</span>
						</label>
					</div>
					<div className='radio-input-wrapper'>
						<input
							type='radio'
							id='advanced'
							name={'format'}
							checked={exportFormat === 'advanced'}
							value={'advanced'}
							onChange={({ target: { value } }) => setExportFormat(value)}
						/>
						<label
							className={`pointer mr-26 text--secondary ${exportFormat === 'advanced' ? 'checked' : ''}
							font--12px line-height--17px font-weight--400 text--dark-blue`}
							htmlFor={'advanced'}
						>
							<span className='position--relative'>
								<Translate text={localizationConstants.advanced} />
								<QuestionMarkIcon className='position--absolute ml-5' id={'advancedInfo'} />
								<Tooltip
									anchorId={'advancedInfo'}
									place='top'
									className='no-padding duplicate-chart-tooltip'
									content={`${t(localizationConstants.advancedInfo)}`}
								/>
							</span>
						</label>
					</div>
				</div>
				<h2 className='font--16px mb-20'>
					<Translate text={localizationConstants.grouping} />
				</h2>
				<div className='full-width text-align-right mt-10 mb-20 d-flex justify-content-start align-items-center gap--10'>
					<div className='full-width text-align-right mt-10 mb-20 d-flex justify-content-start align-items-center gap--10'>
						{chartDetails.notierung === null && !chartDetails.isIntraday && renderExportButtons('allData', 'allData')}
						{(chartDetails.notierung === 'D' || chartDetails.isIntraday) && (
							<>
								{renderExportButtons('allData', 'allData')}
								{chartDetails.isIntraday && renderExportButtons('intraDay', 'intraday')}
								{renderExportButtons('weekly', 'weekly')}
								{renderExportButtons('monthly', 'monthly')}
							</>
						)}
						{chartDetails.notierung === 'W' && (
							<>
								{renderExportButtons('allData', 'weekly')}
								{renderExportButtons('monthly', 'monthly')}
							</>
						)}
						{['M', 'Y', 'Q'].includes(chartDetails.notierung) && renderExportButtons('allData', 'monthly')}
					</div>
				</div>
				<h2 className='font--16px mb-20'>
					<Translate text={localizationConstants.sorting} />
				</h2>
				<div className='d-flex align-items-center mb-16 gap--10'>
					<div className='radio-input-wrapper cursor-pointer'>
						<input
							type='radio'
							id='ASC'
							name={'sorting'}
							checked={exportSorting === 'ASC'}
							value={'ASC'}
							onChange={({ target: { value } }) => setExportSorting(value)}
						/>
						<label
							className={`pointer mr-26 text--secondary ${exportSorting === 'ASC' ? 'checked' : ''}
						font--12px line-height--17px font-weight--400 text--dark-blue`}
							htmlFor={'ASC'}
						>
							<span className='position--relative'>
								<Translate text={localizationConstants.ascending} />
								<QuestionMarkIcon className='position--absolute ml-5' id={'ascendingInfo'} />
								<Tooltip
									anchorId={'ascendingInfo'}
									place='top'
									className='no-padding duplicate-chart-tooltip'
									content={`${t(localizationConstants.ascendingInfo)}`}
								/>
							</span>
						</label>
					</div>
					<div className='radio-input-wrapper'>
						<input
							type='radio'
							id='DESC'
							name={'sorting'}
							checked={exportSorting === 'DESC'}
							value={'DESC'}
							onChange={({ target: { value } }) => setExportSorting(value)}
						/>
						<label
							className={`pointer mr-26 text--secondary ${exportSorting === 'DESC' ? 'checked' : ''}
							font--12px line-height--17px font-weight--400 text--dark-blue`}
							htmlFor={'DESC'}
						>
							<span className='position--relative'>
								<Translate text={localizationConstants.descending} />
								<QuestionMarkIcon className='position--absolute ml-5' id={'descendingInfo'} />
								<Tooltip
									anchorId={'descendingInfo'}
									place='top'
									className='no-padding duplicate-chart-tooltip'
									content={`${t(localizationConstants.descendingInfo)}`}
								/>
							</span>
						</label>
					</div>
				</div>
				{loading && exportFormat === 'advanced' && (
					<div className='position--absolute chart-iframe'>
						<div className='postion--relative'>
							<div className='overlay z-index--1 display-flex-center mt-0 full-height position--absolute'>
								<h2 className='color-white'>
									<Translate text={localizationConstants.generatingChartImages} />
								</h2>
							</div>
							<iframe src={`${process.env.REACT_APP_EXCEL_EXPORT_URL}/${token}/${selectedUuid}`} height={500} width={1100} />
						</div>
					</div>
				)}
			</Fragment>
		</CustomModal>
	);
};

export default AdvanceExportChartDataPopup;
