import isEmpty from 'lodash/isEmpty';
import { useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';

import { State } from 'shared/interface';
import Button from 'shared/components/form/button';
import { getCurrentTime } from 'shared/util/utility';
import httpService from 'shared/services/http.service';
import { API_CONFIG } from 'shared/constants/constants';
import { CloseIcon, MatfoxxIcon } from 'shared/icons/icon';
import { Translate } from 'shared/util/translation/translate';
import localizationConstants from 'shared/util/translation/constants';

import { IJokeInterface, ILoginTime, IMatfoxxData, IOffersInterface } from '../interface/admin';

const MatfoxxBot = () => {
	const [isShowModal, setIsShowModal] = useState(false);
	const [showText, setShowText] = useState(false);
	const [loading, setLoading] = useState(false);
	const [loginTime, setLoginTime] = useState({} as ILoginTime);
	const [matfoxxData, setMatfoxxData] = useState<IMatfoxxData>({ joke: [] as IJokeInterface[], offer: [] as IOffersInterface[] });
	const { matfoxxConfig } = useSelector((state: State) => state.siteConfig);

	const getLoginTime = useCallback(() => {
		httpService
			.get(API_CONFIG.path.getUserLoginTime)
			.then((res) => {
				setLoginTime(res);
			})
			.catch((err) => console.error(err));
	}, []);

	const getMatfoxxData = useCallback(() => {
		setLoading(true);
		httpService
			.get(API_CONFIG.path.fetchMatfoxxData)
			.then((res) => {
				setMatfoxxData(res);
				setLoading(false);
			})
			.catch((err) => {
				console.error(err);
				setLoading(false);
			});
	}, []);

	const stopMatfoxxBot = useCallback(() => {
		setIsShowModal(false);
		httpService
			.put(`${API_CONFIG.path.updateMatfoxxView}?view=1`)
			.then(() => getLoginTime())
			.catch((err) => console.error(err));
	}, []);

	useEffect(() => {
		if (isShowModal) {
			setTimeout(() => {
				setShowText(true);
			}, 3000);
		} else {
			setShowText(false);
		}
	}, [isShowModal]);

	useEffect(() => {
		getMatfoxxData();
		getLoginTime();
	}, [getLoginTime, getMatfoxxData]);

	useEffect(() => {
		if (matfoxxConfig) {
			if (loginTime.created_at !== '' && matfoxxConfig.notification_period === 0 && matfoxxConfig.notifications === 1 && loginTime.view === 0) {
				const currentTime: number = +new Date();
				const time: number = +new Date(loginTime.created_at);
				const difference = currentTime - time;
				if (difference > 60000) {
					setIsShowModal(true);
				} else {
					const interval = setInterval(() => {
						const currentTime: number = +new Date();
						const time: number = +new Date(loginTime.created_at);
						const difference = currentTime - time;
						if (difference > 60000) {
							setIsShowModal(true);
						}
					}, 5000);
					return () => clearInterval(interval);
				}
			} else if (matfoxxConfig.notification_period === 1 && loginTime.view === 0 && matfoxxConfig.notifications === 1) {
				const untilTime = (matfoxxConfig.until?.split(':')[0] as any) * 3600000 + (matfoxxConfig.until?.split(':')[1] as any) * 60000;
				const fromTime = (matfoxxConfig.from?.split(':')[0] as any) * 3600000 + (matfoxxConfig.from?.split(':')[1] as any) * 60000;
				const interval = setInterval(() => {
					if (getCurrentTime() < untilTime && getCurrentTime() > fromTime) {
						setIsShowModal(true);
					}
				}, 5000);
				return () => clearInterval(interval);
			}
		}
	}, [getLoginTime, loginTime, matfoxxConfig]);

	return (
		<React.Fragment>
			{isShowModal && !loading && (matfoxxData.joke || !isEmpty(matfoxxData.offer)) && (
				<>
					<div className='overlay' />
					<div className='position--relative'>
						<div className='slide-left'>
							<MatfoxxIcon width='112' height='150' />
						</div>
						<div className={`position--absolute slide-right custom__matfoxx-popup`}>
							<span className='custom__triangle-shape position--absolute' />
							<div className='position--relative'>
								<div className='d-flex justify-content-space-between align-items-center'>
									<h3
										className={`font--10px font-weight--400 no-margin full-width ${!showText && 'opacity-0'} ${
											showText && 'text-animation'
										}`}
									>
										{isEmpty(matfoxxData.offer) && (
											<Translate
												text={
													isEmpty(matfoxxData.offer)
														? localizationConstants.jokeOfTheDay
														: localizationConstants.offerOfTheDay
												}
											/>
										)}
									</h3>
									<Button
										className={`no-border ${!showText && 'opacity-0'} ${showText && 'text-animation'}`}
										onClick={stopMatfoxxBot}
									>
										<CloseIcon width='12px' height='12px' className='close-icon no-padding' />
										<span className='sr-only'>Close</span>
									</Button>
								</div>
								{isEmpty(matfoxxData.offer) && (
									<div
										className={`d-flex full-width justify-content-center flex-column align-items-center ${
											!showText && 'opacity-0'
										} ${showText && 'text-animation'}`}
									>
										<p
											className={`font--16px full-width break-word text-align-center no-margin  font-weight--500 ${
												!showText && 'opacity-0'
											} ${showText && 'text-animation'}`}
											dangerouslySetInnerHTML={{ __html: matfoxxData.joke[0]?.joke?.replaceAll('\n', '<br />') }}
										/>
									</div>
								)}
								{!isEmpty(matfoxxData.offer) && (
									<div className='d-flex full-width justify-content-center flex-column align-items-center'>
										<p
											className={`font--16px text-align-center full-width break-word font-weight--500 ${
												!showText && 'opacity-0'
											} ${showText && 'text-animation'}`}
										>
											{matfoxxData.offer[0].offer}
										</p>
										<a
											href={matfoxxData.offer[0].link}
											target='_blank'
											className={`full-width break-word text-align-center ${!showText && 'opacity-0'} ${
												showText && 'text-animation'
											}`}
										>
											{matfoxxData.offer[0].link}
										</a>
									</div>
								)}
							</div>
						</div>
					</div>
				</>
			)}
		</React.Fragment>
	);
};

export default MatfoxxBot;
